import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../../api/authApiSlices";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const RecoverPassword = (props) => {
  const [email, setEmail] = useState("");

  const [forgotPassword] = useForgotPasswordMutation();

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    await forgotPassword(email)
      .unwrap()
      .then((res) => {
        navigate("/auth/sign-in")
        return
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  return (
    <>
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-5">
                  <Card.Body>
                    <h3 className="mb-3 text-center">Reset Password</h3>
                    <p className="text-center small text-secondary mb-3">
                      Please enter your email below
                    </p>
                    <Form onClick={onSubmit}>
                      <Row>
                        <Col lg="12">
                          <Form.Group>
                            <Form.Label className="text-secondary">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              required
                              value={email}
                              onChange={(e) =>
                                setEmail(e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button type="submit" className="btn btn-primary btn-block">
                        Send Recovery Email
                      </Button>
                    </Form>
                  </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(RecoverPassword);
