import React from 'react';
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({
    current_page,
    last_page,
    onPageChange
}) => {
    const handlePageChange = (page) => {
        if (page >= 1 && page <= last_page) {
            onPageChange(page);
        }
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxVisiblePages = 5;
        const halfVisible = Math.floor(maxVisiblePages / 2);

        let startPage = Math.max(1, current_page - halfVisible);
        let endPage = Math.min(last_page, current_page + halfVisible);

        // Adjust if near the start or end
        if (current_page <= halfVisible) {
            endPage = Math.min(last_page, maxVisiblePages);
        } else if (current_page > last_page - halfVisible) {
            startPage = Math.max(1, last_page - maxVisiblePages + 1);
        }

        // Add "First" and "Ellipsis" before
        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        // Add middle pages
        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === current_page}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        // Add "Ellipsis" and "Last" after
        if (endPage < last_page) {
            if (endPage < last_page - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item
                    key={last_page}
                    onClick={() => handlePageChange(last_page)}
                >
                    {last_page}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <div className="d-flex align-items-center justify-content-center mb-4">
            <Pagination size="md">
                <Pagination.Prev
                    onClick={() => handlePageChange(current_page - 1)}
                    disabled={current_page === 1}
                />
                {renderPaginationItems()}
                <Pagination.Next
                    onClick={() => handlePageChange(current_page + 1)}
                    disabled={current_page === last_page}
                />
            </Pagination>
        </div>
    );
};

export default CustomPagination;
