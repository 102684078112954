

export const getMeAPiCalling = async (getProfile) => {
    const token =  JSON.parse(localStorage.getItem("token"));
    try {
        const res = await getProfile(token).unwrap();
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(res));
    } catch (error) {
        console.error("Error fetching user profile:", error);
        if(error.status === 401){
            window.location.href = "/auth/sign-in"
            // localStorage.removeItem("token");
        }
    }
};
