import React, { useEffect, useState } from 'react'
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { useGetUserListMutation } from '../../../api/usersAPISlices'
import CustomPagination from '../../../components/utility/CustomPagination'

const colums = [
    { lavel: "Name", key: "name" },
    { lavel: "Email", key: "email" },
    { lavel: "Phone", key: "phone" },
    { lavel: "Role", key: "role" },
    { lavel: "Available Credit", key: "credit_balance" },
    { lavel: "Action", key: "action" }
]

const UsersList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [Users, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [getUserList] = useGetUserListMutation();

    const token = JSON.parse(localStorage.getItem("token"));

    const getUsersData = async () => {
        setIsLoading(true)
        await getUserList({ token, currentPage })
            .unwrap()
            .then((res) => {
                setUsers(res)
                setIsLoading(false)
            })
            .catch((error) => {
                <div>Error: {error}</div>
            })
    }

    useEffect(() => {
        getUsersData()
    }, [currentPage])

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Users</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        {/* <Form className="mr-3 position-relative">
                                            <div className="form-group mb-0">
                                                <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search User" />
                                                <Link className="search-link" to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </Form> */}
                                        <Link to="/users-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add User
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading ?
                            (<div className='text-center mt-5 pt-5'>
                                <div class="spinner-border text-warning mr-2" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </div>)
                            : Users?.data?.length > 0 ?
                                <>
                                    <Row>
                                        <Col lg="12">
                                            <Card className="card-block card-stretch border-back">
                                                <Card.Body className="p-0">
                                                    <div className="table-responsive">
                                                        <table className="table data-table mb-0">
                                                            <thead className="table-color-heading">
                                                                <tr className="text-center">
                                                                    {colums.map((col, i) => (
                                                                        <th key={i} scope="col">
                                                                            {col.lavel}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Users?.data?.map((row, index) => (
                                                                    <tr key={index} className="white-space-no-wrap">
                                                                        {colums.map((col) => (
                                                                            <td className='text-center' key={col.key}>
                                                                                {col?.key === 'action' ?
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                            <Link to={`/users-edit/${row?.id}`}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                    : col?.key === 'name' ?
                                                                                        `${row?.first_name || ""}  ${row?.last_name || ''}`
                                                                                        : row?.[col?.key] || ''
                                                                                }
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" className='text-center'>
                                            <CustomPagination
                                                // from={Users?.meta?.from}
                                                // last_page={Users?.meta?.last_page}
                                                // current_page={currentPage}
                                                // per_page={itemsPerPage}
                                                // total={Users?.meta?.total}
                                                // onPageChange={onPageChange}

                                                current_page={currentPage}
                                                last_page={Users?.meta?.last_page}
                                                onPageChange={onPageChange}
                                            />
                                        </Col>
                                    </Row>
                                </> :
                                <div className='pt-5 mt-5 mb-5 text-center'>
                                    No data found
                                </div>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default UsersList;
