import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import RateSack from '../../../components/Lead/RateSack'
import LoanDetails from '../../../components/Lead/LoanDetails'
import CardWrapper from '../../../components/utility/CardwWrappper'
import RatePrice from '../../../components/Lead/RatePrice'
import { useGetQuoteMutation, useGetRateStackMutation, usePostPreviewMutation, useUpdateLeadMutation, useUpdateQuoteMutation } from '../../../api/leadsAPISlices'
import Toaster from '../../../components/utility/Toaster'

const fromInitialValus = {
    lonePurpose: "Refinance",
    loneProgram: "VA",
    financeTearm: "30Yr Fixed",
    lender: "UWM",
    baseLoanAmount: "",
    cashOut: "",
    loanAmountFixed: "",
    reserveMounts: "",
    processingFee: "",
    revenueType: "",
    BPS: "",
    compensationType: "Borrower Paid",
    lockTerm: "30 Days",
    APOR: null,
    isPrice: "",
    fixedLoanAmount: 0,
    revenueDollar: 0,
    isParOptionsAvailable: false,
}

const LeadeditQuote = () => {
    const [formData, setFormData] = useState(fromInitialValus);
    const [isQuted, setIsQuted] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isGettingData, setIsGettingData] = useState(true);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [isShowRateStack, setIsShowRateStack] = useState(false);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [selectedRates, setSelectedRates] = useState([]);
    const [errors, setErrors] = useState();
    const [defaultPersentage, setDefaultPersentage] = useState();

    const token = JSON.parse(localStorage.getItem("token"));

    const navigate = useNavigate();
    const { leadId } = useParams();

    const [getQuote] = useGetQuoteMutation();
    const [updateQuote] = useUpdateQuoteMutation();
    const [postPreview] = usePostPreviewMutation();

    const checkQMSubmit = () => {
        setIsQuted(true)
    };

    const getQuoteData = async () => {
        setIsGettingData(true)

        await getQuote({ leadId, token })
            .unwrap()
            .then((res) => {
                setIsGettingData(false)
                setErrors([])
                setFormData((pev) => ({
                    ...pev,
                    lonePurpose: res?.loan_purpose,
                    loneProgram: res?.loan_program,
                    financeTearm: res?.finance_term,
                    lender: res?.lender,
                    baseLoanAmount: res?.base_loan_amount,
                    cashOut: res?.cash_out,
                    // loanAmountFixed: res?.is_loan_amount_fixed,
                    loanAmountFixed: res?.is_loan_amount_fixed === true ? "1" : "0",
                    fixedLoanAmount: res?.fixed_loan_amount,
                    reserveMounts: res?.reserve_months,
                    processingFee: res?.processing_fee,
                    revenueType: res?.revenue_type,
                    BPS: res?.revenue_percentage,
                    compensationType: res?.compensation_type,
                    lockTerm: res?.lock_term_days,
                    APOR: res?.apor,
                    isPrice: res?.is_fees_in_price,
                    revenueDollar: res?.revenue_dollar,
                    isParOptionsAvailable: res?.is_par_options_available
                }))
                setDefaultPersentage(res?.revenue_percentage)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setIsGettingData(false)
                setErrors(error?.data?.errors)

            })
    }

    useEffect(() => {
        getQuoteData()
    }, [])

    const handlePriview = async () => {
        setIsPreviewLoading(true)

        const newobject = selectedRates.map(item => ({
            rate: item.rate,
            price: item.price
        }));

        const payload = {
            options: newobject
        }

        localStorage.setItem("options", JSON.stringify(payload))

        await postPreview({ payload, leadId, token })
            .unwrap()
            .then((res) => {
                localStorage.setItem("preview", JSON.stringify(res));

                navigate(`/lead-view/${leadId}`);

                setIsPreviewLoading(false)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')

                setIsPreviewLoading(false)
            })
    }

    const handleSave = async () => {

        setIsLoading(true)

        const payload = {
            loan_purpose: formData?.lonePurpose,
            loan_program: formData?.loneProgram,
            finance_term: formData?.financeTearm,
            lender: formData?.lender,
            base_loan_amount: formData?.baseLoanAmount,
            cash_out: formData?.cashOut,
            is_loan_amount_fixed: formData?.loanAmountFixed === "1" ? true : false,
            fixed_loan_amount: formData?.fixedLoanAmount,
            reserve_months: formData?.reserveMounts,
            processing_fee: formData?.processingFee,
            revenue_type: formData?.revenueType,
            revenue_percentage: formData.revenueType === "Dollar Amount" ? null : formData?.BPS,
            compensation_type: formData?.compensationType,
            lock_term_days: formData?.lockTerm,
            apor: formData?.APOR,
            is_fees_in_price: formData?.isPrice,
            revenue_dollar: formData.revenueType === "Dollar Amount" ? formData?.revenueDollar : null,
            is_par_options_available: formData?.isParOptionsAvailable,
        }

        return await updateQuote({ payload, leadId, token })
            .unwrap()
            .then((res) => {
                setShowToster(true)
                setTosterMassage("Done!")
                setTosterStatus('success')

                setIsShowRateStack(true)
                setIsLoading(false)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')

                setIsLoading(false)
            })
    }

    return (
        isGettingData ?
            (<div className='text-center mt-5 pt-5'>
                <div class="spinner-border text-warning mr-2" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>)
            : <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/leads">Lead > Quote</Link></li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to={`/lead-edit/${leadId}`} className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>

                        {/* loan details  */}
                        <Col lg="12">
                            <CardWrapper title="Loan Details">
                                <LoanDetails formData={formData} setFormData={setFormData} error={errors} defaultPersentage={defaultPersentage} />
                            </CardWrapper>
                        </Col>

                        <Col lg="12">
                            <CardWrapper title="Rate Stack">
                                <RateSack selectedRates={selectedRates} setSelectedRates={setSelectedRates} leadId={leadId} token={token} formData={formData} handleSave={handleSave} isGettingData={isGettingData} />
                            </CardWrapper>
                        </Col>

                        {/*  Rate Price */}
                        <Col lg="12">
                            <CardWrapper title="Rate Price">
                                <RatePrice selectedRates={selectedRates} isQuted={!isQuted} />
                            </CardWrapper>
                        </Col>

                    </Row>

                    <div className="d-flex flex-wrap justify-content-end mt-0 mb-5">
                        <Button onClick={handleSave} variant="btn btn-success  font-weight-bold btn-sm py-2 px-3 mr-2">
                            <div
                                style={{
                                    transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                }}
                            >
                                <div className="spinner-border text-warning loadding-spinner mr-2"
                                    style={{
                                        opacity: isLoading ? 100 : 0,
                                    }}
                                    role="status"
                                >
                                    <span className="visually-hidden"></span>
                                </div>

                                <span>
                                    Save
                                </span>
                            </div>
                        </Button>

                        <Button onClick={handlePriview} variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                            <div
                                style={{
                                    transform: !isPreviewLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                }}
                            >
                                <div className="spinner-border text-warning loadding-spinner mr-2"
                                    style={{
                                        opacity: isPreviewLoading ? 100 : 0,
                                    }}
                                    role="status"
                                >
                                    <span className="visually-hidden"></span>
                                </div>

                                <span>Preview</span>
                            </div>
                        </Button>
                    </div>
                </Container>

                <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
            </>
    )
}

export default LeadeditQuote;
