import { apiBaseSlices } from "./baseAPI";

export const usersSlices = apiBaseSlices.injectEndpoints({
    endpoints: (builder) => ({
        getUserList: builder.mutation({
            query: ({ token, currentPage }) => ({
                url: `users?page=${currentPage}`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        getUser: builder.mutation({
            query: ({ userId, token }) => {
                return {
                    url: `users/${userId}`,
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            },
        }),

        createUser: builder.mutation({
            query: ({ payload, token }) => {
                return {
                    url: "users",
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: payload,
                };
            },
        }),

        updateUser: builder.mutation({
            query: ({ payload, userId, token }) => ({
                url: `users/${userId}`,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        changeRole: builder.mutation({
            query: ({ payload, userId, token }) => ({
                url: `users/${userId}/change-role`,
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        changePasswrod: builder.mutation({
            query: ({ payload, token }) => {
                return {
                    url: `change-password`,
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: payload,
                };
            },
        }),

        setAutoRefilThreshold: builder.mutation({
            query: ({ payload, token }) => {
                return {
                    url: `set-credit-threshold`,
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: payload,
                };
            },
        }),

        reCharge: builder.mutation({
            query: ({ payload, token }) => {
                return {
                    url: `recharge`,
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: payload,
                };
            },
        }),

        dashboard: builder.mutation({
            query: ({ payload, token }) => ({
                url: `dashboard`,
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: payload,
            }),
        }),

    }),
});

export const {
    useGetUserListMutation,
    useGetUserMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useChangePasswrodMutation,
    useChangeRoleMutation,
    useSetAutoRefilThresholdMutation,
    useReChargeMutation,
    useDashboardMutation,
} = usersSlices;
