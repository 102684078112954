import React, { useRef, useState } from 'react'
import { Container, Row, Col, Tab, Form, Button } from 'react-bootstrap'
import { PatternFormat } from "react-number-format";

//img 
import user1 from '../../../assets/images/user/1.avif'
import Card from '../../../components/Card'
import { useCreateUserMutation } from '../../../api/usersAPISlices'
import { useNavigate } from 'react-router-dom'
import Toaster from '../../../components/utility/Toaster'
import Multiselect from 'multiselect-react-dropdown';
import states from "../../../assets/json/states.json";

const initialValus = {
    fristName: '',
    lastname: '',
    email: '',
    phone: '',
    nmls: "",
    password: "",

    lenderPaidCompensation: '',
    licensedStates: [],
    defaultTitleCharges: '',
    defaultCreditAdVerificationFee: "",
    defaultAppraisalFee: '',
    companyNmls: "",
    companyName: "",
    companyLogo: "",
}

const UsersAdd = () => {
    const [formData, setFormData] = useState(initialValus);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const fileInputRef = useRef(null);

    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [errors, setErrors] = useState();

    const navigate = useNavigate();

    const [createUser] = useCreateUserMutation();

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedImage(file);
        }
    };

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSelect = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const handleRemove = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const selectedObjects = states.filter((state) =>
        formData.licensedStates.includes(state.value)
    );

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const payloadData = {
            first_name: formData.fristName,
            last_name: formData.lastname,
            email: formData.email,
            password: formData.password,
            nmls: formData.nmls,
            phone: formData.phone,
            licensed_states: formData.licensedStates,
            lender_paid_compensation: formData.lenderPaidCompensation,
            default_appraisal_fee: formData.defaultAppraisalFee,
            company_name: formData.companyName,
            company_nmls: formData.companyNmls,
            default_services_you_cannot_shop_for_fee: formData.defaultCreditAdVerificationFee,
            default_services_you_can_shop_for_fee: formData.defaultTitleCharges,
        };

        // Assuming uploadedImage is a File object, you can append it separately:
        const payload = new FormData();

        for (const [key, value] of Object.entries(payloadData)) {
            if (Array.isArray(value)) {
                value.forEach((item) => payload.append(`${key}[]`, item));
            } else {
                payload.append(key, value);
            }
        }

        if (uploadedImage) {
            payload.append('company_logo', uploadedImage);
        }

        const token = JSON.parse(localStorage.getItem("token"));

        await createUser({ payload, token })
            .unwrap()
            .then((res) => {
                setErrors([])
                navigate("/users-list");
                setIsLoading(false)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setErrors(error?.data?.errors)

                setIsLoading(false)
            });
    };

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information mb-5">
                    <Row>

                        <Col lg="12" className='mb-5'>
                            <Card>
                                <Card.Header>
                                    <Card.Header.Title>
                                        <h4 className="card-title">User Information</h4>
                                    </Card.Header.Title>
                                </Card.Header>

                                <Card.Body>
                                    <Form onSubmit={onSubmit}>
                                        <Row className="form-group align-items-center mb-4">
                                            <Col md="12">
                                                <p className='font-weight-bold mb-1' style={{ fontSize: '18px' }}>Company Logo :</p>
                                                <div className="profile-img-edit" style={{ display: "inline-block", cursor: "pointer" }} onClick={handleDivClick}>
                                                    <div className="crm-profile-img-edit">
                                                        <img
                                                            className="crm-profile-pic rounded-3 avatar-100"
                                                            src={uploadedImage ? URL.createObjectURL(uploadedImage) : user1}
                                                            alt="profile-pic"
                                                        />
                                                        <div className="crm-p-image bg-primary">
                                                            <i className="las la-pen upload-button"></i>
                                                            <input
                                                                ref={fileInputRef}
                                                                className="file-upload"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleImageUpload} // Handle image upload
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Form.Group controlId="fname" as={Col} sm="6" md="4">
                                                <Form.Label>First Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter First Name"
                                                    required
                                                    value={formData.fristName}
                                                    onChange={(e) =>
                                                        handleInputChange("fristName", e.target.value)
                                                    }
                                                    isInvalid={errors?.first_name && errors?.first_name?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.first_name?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="lname" as={Col} sm="6" md="4">
                                                <Form.Label>Last name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter Last Name'
                                                    required
                                                    value={formData.lastname}
                                                    onChange={(e) =>
                                                        handleInputChange("lastname", e.target.value)
                                                    }
                                                    isInvalid={errors?.last_name?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.last_name?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="email" as={Col} sm="6" md="4">
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder='Enter Email'
                                                    required
                                                    value={formData.email}
                                                    onChange={(e) =>
                                                        handleInputChange("email", e.target.value)
                                                    }
                                                    isInvalid={errors?.email?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.email?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="phone" as={Col} sm="6" md="4">
                                                <Form.Label>Phone:</Form.Label>
                                                <PatternFormat
                                                    type="tel"
                                                    format="(###) ###-####"
                                                    mask="_"
                                                    value={formData.phone}
                                                    onValueChange={(values) => handleInputChange("phone", values.formattedValue)}
                                                    placeholder="(123) 456-7890"
                                                    required
                                                    className="form-control"
                                                />
                                                {errors?.phone &&
                                                    <p type="invalid" className='invalid text-danger' style={{ fontSize: '14px' }} >
                                                        {errors?.phone}
                                                    </p>
                                                }
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>NMLS:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder='Enter Nmls'
                                                    required
                                                    value={formData.nmls}
                                                    onChange={(e) =>
                                                        handleInputChange("nmls", e.target.value)
                                                    }
                                                    isInvalid={errors?.nmls?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.nmls?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>Lender Paid Compensation (%) :</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder=''
                                                    // required
                                                    value={formData.lenderPaidCompensation}
                                                    onChange={(e) =>
                                                        handleInputChange("lenderPaidCompensation", e.target.value)
                                                    }
                                                    isInvalid={errors?.lender_paid_compensation?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.lender_paid_compensation?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>Default Services You Can Shop For Fee ($) :</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formData.defaultTitleCharges}
                                                    onChange={(e) =>
                                                        handleInputChange("defaultTitleCharges", e.target.value)
                                                    }
                                                    isInvalid={errors?.default_services_you_can_shop_for_fee?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.default_services_you_can_shop_for_fee?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>Default Services You Can't Shop For Fee ($) :</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={formData.defaultCreditAdVerificationFee}
                                                    onChange={(e) =>
                                                        handleInputChange("defaultCreditAdVerificationFee", e.target.value)
                                                    }
                                                    isInvalid={errors?.default_services_you_cannot_shop_for_fee?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.default_services_you_cannot_shop_for_fee?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>Company NMLS:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder=''
                                                    value={formData.companyNmls}
                                                    onChange={(e) =>
                                                        handleInputChange("companyNmls", e.target.value)
                                                    }
                                                    isInvalid={errors?.company_nmls?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.company_nmls?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                <Form.Label>Company Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder=''
                                                    // required
                                                    value={formData.companyName}
                                                    onChange={(e) =>
                                                        handleInputChange("companyName", e.target.value)
                                                    }
                                                    isInvalid={errors?.company_name?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.company_name?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} sm="6" md="4">
                                                <Form.Label htmlFor="inputState">Licensed States</Form.Label>
                                                <Multiselect
                                                    options={states}
                                                    selectedValues={selectedObjects}
                                                    onSelect={handleSelect}
                                                    onRemove={handleRemove}
                                                    displayValue="name"
                                                    placeholder="Select Licensed States"
                                                    style={{
                                                        optionContainer: {
                                                            height: "150px",
                                                        }
                                                    }}
                                                    isInvalid={errors?.licensed_states?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.licensed_states?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group controlId="password" as={Col} sm="6" md="4">
                                                <Form.Label>Password:</Form.Label>
                                                <Form.Control
                                                    type="Password"
                                                    placeholder='Enter Password'
                                                    required
                                                    value={formData.password}
                                                    onChange={(e) =>
                                                        handleInputChange("password", e.target.value)
                                                    }
                                                    isInvalid={errors?.password?.length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.password?.[0]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Button type="submit" variant="primary">
                                            <div
                                                style={{
                                                    transform: !isLoading ? 'translateX(-1.2rem)' : 'translateX(0)'
                                                }}
                                            >
                                                <div className="spinner-border text-warning loadding-spinner mr-2"
                                                    style={{
                                                        opacity: isLoading ? 100 : 0,
                                                    }}
                                                    role="status"
                                                >
                                                    <span className="visually-hidden"></span>
                                                </div>

                                                <span>Submit</span>
                                            </div>
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
        </>
    )
}

export default UsersAdd