import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import PopupWrapper from "../utility/PopupWrapper";
import { useSetAutoRefilThresholdMutation } from "../../api/usersAPISlices";
import { useGetProfileMutation } from "../../api/authApiSlices";
import { getMeAPiCalling } from "../utility/getMeData";
import Toaster from "../utility/Toaster";
import CommonButton from "../utility/CommonButton";

const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));

const initialValus = {
    minimumBalance: user?.minimum_credit_threshold || null,
    rechargeAmount: user?.auto_refill_amount || null,
}

const AutoRecharge = () => {
    const [formData, setFormData] = useState(initialValus);
    const [isLoading, setIsLoading] = useState(false);
    const [isAutoTopup, setIsAutoTopup] = useState(user?.auto_refill_amount === null ? false : true)
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [errors, setErrors] = useState();

    const [setAutoRefilThreshold] = useSetAutoRefilThresholdMutation();

    const [getProfile] = useGetProfileMutation()
    const logdenUerData = async () => {
        await getMeAPiCalling(getProfile)
    }

    const handleSwitchChange = async (e) => {
        setIsAutoTopup(!isAutoTopup)

        const payload = {
            credit_threshold: "400",
            auto_refill_amount: null,
        }

        if (!isAutoTopup && user?.auto_refill_amount !== null) {
            await setAutoRefilThreshold({ payload, token })
                .unwrap()
                .then((res) => {
                    setIsAutoTopup(true);
                    setIsLoading(false);
                    logdenUerData()

                    setShowToster(true)
                    setTosterMassage("Disable Auto-Topup")
                    setTosterStatus('success')
                })
                .catch((error) => {
                    console.error("Error creating user:", error);
                    setIsLoading(false)

                    setShowToster(true)
                    setTosterMassage(error?.data?.message)
                    setTosterStatus('error')
                });
        }
    };

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true)

        const payload = {
            credit_threshold: formData.minimumBalance,
            auto_refill_amount: formData.rechargeAmount,
        }

        await setAutoRefilThreshold({ payload, token })
            .unwrap()
            .then((res) => {
                setIsAutoTopup(true);
                setIsLoading(false)
                logdenUerData()

                setShowToster(true)
                setTosterMassage("Enable Auto-Topup")
                setTosterStatus('success')
                setErrors([])
            })
            .catch((error) => {
                setIsLoading(false)

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setErrors(error?.data?.errors)
                
            });
    };

    return (
        <>
            <Card className="p-3 shadow-sm bg-info-subtle shadow-lg border border-light mb-2">
                <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="fw-bold mb-0 ml-0 pl-0">
                            Enable Auto-Topup
                        </h5>
                        <div>
                            <Form.Check
                                type="switch"
                                label=""
                                id="disabled-custom-switch"
                                style={{ transform: 'scale(1.5)' }}

                                checked={isAutoTopup}
                                onClick={handleSwitchChange}
                            />
                        </div>
                    </div>

                </Card.Body>

                <Row className="mt-3">
                    <p className="px-2">
                        This option will allow you to topup your wallet, once it reaches the minimum threshold.
                    </p>
                </Row>

                <hr className="m-0" />

                {isAutoTopup &&
                    <Form onSubmit={onSubmit} className="mt-3">
                        <Row>
                            <Col lg="12">
                                <Form.Group controlId="minimumBalance">
                                    <Form.Label className="font-weight-bold">
                                        Set Minimum Balance
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter  Set Minimum Balance"
                                        required
                                        value={formData.minimumBalance}
                                        onChange={(e) =>
                                            handleInputChange("minimumBalance", e.target.value)
                                        }
                                        isInvalid={errors?.credit_threshold && errors?.credit_threshold?.length > 0}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.credit_threshold?.[0]}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg="12" className="mt-2">
                                <Form.Group>
                                    <Form.Label className="font-weight-bold">
                                        Auto Recharge Amount
                                    </Form.Label>
                                    <Form.Control
                                        // type="number"
                                        placeholder="Enter Auto Recharge Amount"
                                        required
                                        value={formData.rechargeAmount}
                                        onChange={(e) =>
                                            handleInputChange("rechargeAmount", e.target.value)
                                        }
                                        isInvalid={errors?.auto_refill_amount && errors?.auto_refill_amount?.length > 0}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.auto_refill_amount?.[0]}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Button type="submit" className="btn btn-primary mt-2">
                            {isLoading && <div className="spinner-border text-warning mr-2" style={{ width: "1.5rem", height: "1.5rem" }} role="status">
                                <span className="visually-hidden"></span>
                            </div>}
                            <span>Submit</span>

                        </Button> */}
                        <CommonButton isLoading={isLoading} text="Submit" /> 
                    </Form>
                }
            </Card>

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
        </>
    )
}

export default AutoRecharge
