import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card';
import { useAddPaymentMethodsMutation } from '../../../api/paymentAPISlices';

const personalInfoInitialValus = {
    cardType: '',
    last4Digit: '',
    exp: '',
    phone: '',
}

const PaymentAdd = () => {
    const [formData, setFormData] = useState(personalInfoInitialValus);

    const [addPaymentMethods] = useAddPaymentMethodsMutation();

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const payload ={

        }
        
        await addPaymentMethods()
        .unwrap()
        .then((res)=>{
            //
        })
        .catch((error)=>{
            console.log("error", error)
        })
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Header>
                                <Card.Header.Title>
                                    <h4 className="card-title">Add Card</h4>
                                </Card.Header.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form onClick={onSubmit}>
                                    <Row className=" align-items-center">
                                        <Form.Group controlId="fname" as={Col} sm="6" >
                                            <Form.Label>Card Type:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter Card Type'
                                                required
                                                value={formData.cardType}
                                                onChange={(e) =>
                                                    handleInputChange("cardType", e.target.value)
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="lname" as={Col} sm="6">
                                            <Form.Label>Last 4 digit:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder='Enter Last 4 digit'
                                                required
                                                value={formData.last4Digit}
                                                onChange={(e) =>
                                                    handleInputChange("last4Digit", e.target.value)
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="exp" as={Col} sm="6">
                                            <Form.Label>EXP:</Form.Label>
                                            <Form.Control
                                                type="exp"
                                                placeholder='Enter EXP'
                                                required
                                                value={formData.exp}
                                                onChange={(e) =>
                                                    handleInputChange("exp", e.target.value)
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="phone" as={Col} sm="6">
                                            <Form.Label>Phone:</Form.Label>
                                            <Form.Control
                                                type="phone"
                                                placeholder='Enter Phone'
                                                required
                                                value={formData.phone}
                                                onChange={(e) =>
                                                    handleInputChange("phone", e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                    </Row>
                                    {/* <Button type="reset" variant="outline-primary mr-2">Cancel</Button> */}
                                    <Button type="submit" variant="primary" className='mt-2'>Submit</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PaymentAdd;