import { Modal } from "react-bootstrap"


const PopupWrapper = ({title, show, setShow, children}) =>{

    return(
        <Modal
            show={show}
            onHide={()=>setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default PopupWrapper
