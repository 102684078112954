import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../../api/authApiSlices";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const ResetPassword = () => {
  const [passowrd, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  const [resetPassword] = useResetPasswordMutation();

  const navigate = useNavigate();
  const {email, token} = useParams();

  const onResetPassoword = async () =>{
    const payload = {
      email : email,
      passowrd : passowrd,
      password_confirmation : confirmationPassword,
      token : token
    }

    await resetPassword(payload)
      .unwrap()
      .then((res) => {
        navigate("/auth/sign-in")
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  }

  return (
    <>
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-5">
                  <Card.Body>
                    <h3 className="mb-2 text-center">Reset Password</h3>
                    <p className="text-center small mb-3">
                      You can reset your password here
                    </p>
                    <Form onClick={onResetPassoword}>
                      <Row>
                        <Col lg="12">
                          <Form.Group>
                            <Form.Label className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter Password"
                              required
                              value={passowrd}
                              onChange={(e) =>
                                setPassword(e.target.value)
                              }
                            />
                            <Form.Label className=" mt-2">
                              Confirmation Password
                            </Form.Label>
                            <Form.Control
                              type="confirmationPassword"
                              placeholder="Enter Confirmation Password"
                              required
                              value={confirmationPassword}
                              onChange={(e) =>
                                setConfirmationPassword(e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button type="submit" className="btn btn-primary btn-block">
                        Reset Password
                      </Button>
                    </Form>
                  </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(ResetPassword);
