import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'

//img
// import logo from '../../../../../src/assets/images/logo.png'
import smalLogo from '../../../../../src/assets/images/favicon.png'
import logo from '../../../../assets/images/logo.webp'
import leadsIcon from '../../../../assets/images/leads-icon.png'

import { useNavigate } from "react-router-dom";
import { getMeAPiCalling } from '../../../utility/getMeData';
import { useGetProfileMutation } from '../../../../api/authApiSlices';

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}

const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}

const SidebarStyle = (props) => {

    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem("user"))
    const token = JSON.parse(localStorage.getItem("token"));

    //location
    let location = useLocation();

    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }

    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)

    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )

    const [getProfile] = useGetProfileMutation()
    const logdenUerData = async() =>{
        await getMeAPiCalling(getProfile)
    }

    useEffect(() => {
        logdenUerData()
        if (!token) {
            navigate("/auth/sign-in");
        }
    }, [navigate, token]);

    

    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`}>
                <div className="iq-sidebar-logo d-flex justify-content-between">
                    <Link to="/" className="header-logo mb-3">
                        <img src={logo} className={`img-fluid big-logo rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                        <img src={smalLogo} className={`img-fluid smal-logo rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Dashboard</span>
                                    {/* <p className="mb-0 w-10 badge badge-pill badge-primary">6</p> */}
                                </Link>
                            </li>
                            <li className="px-3 pt-3 pb-2 ">
                                <span className="text-uppercase small font-weight-bold">Application</span>
                            </li>
                            <li className={`${location.pathname === '/leads' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/leads" className="svg-icon ">
                                    <i className="">

                                        <img src={leadsIcon} width={25} alt='leads icon' />
                                    </i>
                                    <span className="ml-2">Leads</span>
                                </Link>
                            </li>
                            {user?.role === "admin" && <li className={`${location.pathname === '/users-list' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/users-list" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Users</span>
                                </Link>
                            </li>}
                            <li className={`${location.pathname === '/profile-edit' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/profile-edit" className="svg-icon">
                                    <i className="">
                                        <svg className="svg-icon" id="iq-user-1-1" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </i><span className="ml-2">Profile</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/payment' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/payment" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Billing</span>
                                </Link>
                            </li>
                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
            </div>
        </>
    )
}

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle)