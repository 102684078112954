import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Tab, Form, Button, Nav } from 'react-bootstrap'
import { PatternFormat } from "react-number-format";
import imageCompression from 'browser-image-compression';

//img 
import user1 from '../../../assets/images/user/1.avif'
import imagePlaceholder from '../../../assets/images/user/image-placeholder.png';
import Card from '../../../components/Card'
import { useChangeRoleMutation, useGetUserMutation, useUpdateUserMutation } from '../../../api/usersAPISlices'
import { useNavigate, useParams } from 'react-router-dom'
import Toaster from '../../../components/utility/Toaster'
import Multiselect from 'multiselect-react-dropdown';
import { retry } from '@reduxjs/toolkit/query';
import { useGetProfileMutation } from '../../../api/authApiSlices';
import { getMeAPiCalling } from '../../../components/utility/getMeData';
import states from "../../../assets/json/states.json";

const initialValus = {
    fristName: '',
    lastname: '',
    email: '',
    phone: '',
    nmls: "",
    photo: '',
    role: '',
    managerId: null,
    isActive: null,
    creditBalance: 0,

    lenderPaidCompensation: '',
    licensedStates: [],
    defaultTitleCharges: '',
    defaultCreditAdVerificationFee: "",
    defaultAppraisalFee: '',
    companyNmls: "",
    companyName: "",
    companyLogo: "",
    defaultProcessingFee: 0,
    underwritingFee: 0,
    CostPerLead: 0,
}

const UsersEdit = () => {
    const [formData, setFormData] = useState(initialValus);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialData, setIsInitialData] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [companyImage, setCompanyImage] = useState(null);
    const fileInputRef = useRef(null);
    const companyImageRef = useRef(null);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [errors, setErrors] = useState();

    const { userId } = useParams();

    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));

    const [getUser] = useGetUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [changeRole] = useChangeRoleMutation();

    const getUserByID = async () => {

        setIsInitialData(true)

        try {
            const response = await getUser({ userId, token }).unwrap();
            const updateusesr = {
                fristName: response?.first_name,
                lastname: response?.last_name,
                email: response?.email,
                phone: response?.phone,
                role: response?.role,
                nmls: response?.nmls,
                managerId: response?.manager_id,
                isActive: response?.is_active,
                creditBalance: response?.credit_balance,
                photo: response?.photo,
                licensedStates: response?.licensed_states,
                lenderPaidCompensation: response?.lender_paid_compensation,
                defaultAppraisalFee: response?.default_appraisal_fee,
                companyName: response?.company_name,
                companyNmls: response?.company_nmls,
                defaultCreditAdVerificationFee: response?.default_services_you_cannot_shop_for_fee,
                defaultTitleCharges: response?.default_services_you_can_shop_for_fee,
                companyLogo: response?.company_logo,
                CostPerLead: response?.cost_per_lead,
                defaultProcessingFee: response?.default_processing_fee,
                underwritingFee: response?.underwriting_fee,
            }
            setFormData(updateusesr);
            setIsInitialData(false)
        } catch (error) {
            setShowToster(true)
            setTosterMassage(error?.data?.message)
            setTosterStatus('error')
            setIsInitialData(false)
        }
    };

    useEffect(() => {
        getUserByID();
    }, [userId]);

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleImageUpload = async (event, type) => {
        const file = event.target.files[0];
        if (file) {

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(file, options);

                if (type === "company") {
                    setCompanyImage(compressedFile)
                } else {
                    setUploadedImage(compressedFile);
                }

            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handlecompanyImageRef = () => {
        if (companyImageRef.current) {
            companyImageRef.current.click();
        }
    };

    const handleSelect = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const handleRemove = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const selectedObjects = formData?.licensedStates?.length > 0 && states.filter((state) =>
        formData?.licensedStates.includes(state.value)
    );

    const [getProfile] = useGetProfileMutation()
    const logdenUerData = async () => {
        await getMeAPiCalling(getProfile)

        window.location.reload();
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        const payloadData = {
            first_name: formData.fristName,
            last_name: formData.lastname,
            nmls: formData.nmls,
            phone: formData.phone,
            licensed_states: formData.licensedStates || [],
            lender_paid_compensation: formData.lenderPaidCompensation,
            default_appraisal_fee: formData.defaultAppraisalFee,
            company_name: formData.companyName,
            company_nmls: formData.companyNmls,
            default_services_you_cannot_shop_for_fee: formData.defaultCreditAdVerificationFee,
            default_services_you_can_shop_for_fee: formData.defaultTitleCharges,
            default_processing_fee: formData?.defaultProcessingFee,
            underwriting_fee: formData?.underwritingFee,
            cost_per_lead: formData?.CostPerLead,
            _method: "PUT"
        };

        const payload = new FormData();
        for (const [key, value] of Object.entries(payloadData)) {
            if (Array.isArray(value)) {
                value.forEach((item) => payload.append(`${key}[]`, item));
            } else {
                payload.append(key, value);
            }
        }

        if (uploadedImage) {
            payload.append('photo', uploadedImage);
        }

        if (companyImage) {
            payload.append('company_logo', companyImage);
        }

        await updateUser({ payload, userId, token })
            .unwrap()
            .then((res) => {

                logdenUerData()
                // navigate("/users-list");
                setIsLoading(false)
            })
            .catch((error) => {
                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')

                setIsLoading(false)
            });
    };

    const handleChangeRole = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const payload = {
            role: formData.role
        }

        await changeRole({ payload, userId, token })
            .unwrap()
            .then((res) => {
                setErrors([])
                setShowToster(true)
                setTosterMassage("Role changeed sucessfully")
                setTosterStatus('success')

                setIsLoading(false)
            })
            .catch((error) => {
                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setErrors(error?.data?.errors)

                setIsLoading(false)
            })
    };

    return (
        isInitialData
            ? (<div className='text-center mt-5 pt-5'>
                <div class="spinner-border text-warning mr-2" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>) : <>
                <Container fluid>
                    <Tab.Container defaultActiveKey="personal-information">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <Card.Body className="p-0 text-center">
                                        <div className="mm-edit-list usr-edit text-center">
                                            <Nav variant="pills" className="mm-edit-profile d-flex">
                                                <li className="col-md-3 p-0">
                                                    <Nav.Link eventKey="personal-information">User Information</Nav.Link>
                                                </li>
                                                <li className="col-md-3 p-0">
                                                    <Nav.Link eventKey="chang-role">Change Role</Nav.Link>
                                                </li>
                                            </Nav>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="12">
                                <div className="mm-edit-list-data">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="personal-information" role="tabpanel">
                                            <Card>
                                                <Card.Header>
                                                    <Card.Header.Title>
                                                        <h4 className="card-title">User Information</h4>
                                                    </Card.Header.Title>
                                                </Card.Header>

                                                <Card.Body>
                                                    <Form onSubmit={onSubmit}>
                                                        <Row className="form-group align-items-center">
                                                            <Col sm="6" md="4">
                                                                <div
                                                                    className="profile-img-edit"
                                                                    style={{ display: "inline-block", cursor: "pointer" }}
                                                                    onClick={handleDivClick}
                                                                >
                                                                    <p className='font-weight-bold mb-1' style={{ fontSize: '18px' }}>User Photo:</p>
                                                                    <div className="crm-profile-img-edit">
                                                                        <img
                                                                            className="crm-profile-pic rounded-circle avatar-100"
                                                                            src={uploadedImage ? URL.createObjectURL(uploadedImage) : formData?.photo ? formData?.photo : user1}
                                                                            alt="profile-pic"
                                                                        />
                                                                        <div className="crm-p-image bg-primary">
                                                                            <i className="las la-pen upload-button"></i>
                                                                            <input
                                                                                ref={fileInputRef}
                                                                                className="file-upload"
                                                                                type="file"
                                                                                accept="image/*"
                                                                                onChange={handleImageUpload}
                                                                                style={{ display: "none" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm="6" md="4">
                                                                <div
                                                                    className="profile-img-edit"
                                                                    style={{ display: "inline-block", cursor: "pointer" }}
                                                                    onClick={handlecompanyImageRef}
                                                                >
                                                                    <p className='font-weight-bold mb-1' style={{ fontSize: '18px' }}>Company Logo:</p>
                                                                    <div className="crm-profile-img-edit">
                                                                        <img
                                                                            className="crm-profile-pic rounded-3 border shadow-lg"
                                                                            src={companyImage ? URL.createObjectURL(companyImage) : formData?.companyLogo ? formData?.companyLogo : imagePlaceholder}
                                                                            style={{ maxHeight: 100 }}
                                                                            alt="profile-pic"
                                                                        />
                                                                        <div className="crm-p-image bg-primary">
                                                                            <i className="las la-pen upload-button"></i>
                                                                            <input
                                                                                ref={companyImageRef}
                                                                                className="file-upload"
                                                                                type="file"
                                                                                accept="image/*"
                                                                                onChange={(e) => handleImageUpload(e, "company")}
                                                                                style={{ display: "none" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>

                                                        <Row className=" align-items-center">
                                                            <Form.Group controlId="fname" as={Col} sm="6" md="4" >
                                                                <Form.Label>First Name:</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder='Enter First Name'
                                                                    required
                                                                    value={formData.fristName}
                                                                    onChange={(e) =>
                                                                        handleInputChange("fristName", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.first_name && errors?.first_name?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.first_name?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="lname" as={Col} sm="6" md="4">
                                                                <Form.Label>Last Name:</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder='Enter Last Name'
                                                                    required
                                                                    value={formData.lastname}
                                                                    onChange={(e) =>
                                                                        handleInputChange("lastname", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.last_name?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.last_name?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="email" as={Col} sm="6" md="4">
                                                                <Form.Label>Email:</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    disabled
                                                                    placeholder='Enter Email'
                                                                    required
                                                                    value={formData.email}
                                                                    // onChange={(e) =>
                                                                    //     handleInputChange("email", e.target.value)
                                                                    // }
                                                                    isInvalid={errors?.email?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.email?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="phone" as={Col} sm="6" md="4">
                                                                <Form.Label>Phone:</Form.Label>
                                                                {/* <Form.Control
                                                                type="phone"
                                                                placeholder='Enter Phone'
                                                                required
                                                                value={formData.phone}
                                                                onChange={(e) =>
                                                                    handleInputChange("phone", e.target.value)
                                                                }
                                                            /> */}
                                                                <PatternFormat
                                                                    type="tel"
                                                                    format="(###) ###-####"
                                                                    mask="_"
                                                                    value={formData.phone}
                                                                    onValueChange={(values) => handleInputChange("phone", values.formattedValue)}
                                                                    placeholder="(123) 456-7890"
                                                                    required
                                                                    className="form-control"
                                                                />
                                                                {errors?.phone &&
                                                                    <p type="invalid" className='invalid text-danger' style={{ fontSize: '14px' }} >
                                                                        {errors?.phone}
                                                                    </p>
                                                                }
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>NMLS:</Form.Label>
                                                                <Form.Control
                                                                    type="role"
                                                                    placeholder='Enter Nmls'
                                                                    required
                                                                    value={formData.nmls}
                                                                    onChange={(e) =>
                                                                        handleInputChange("nmls", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.nmls?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.nmls?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Lender Paid Compensation (%) :</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=''
                                                                    // required
                                                                    value={formData.lenderPaidCompensation}
                                                                    onChange={(e) =>
                                                                        handleInputChange("lenderPaidCompensation", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.lender_paid_compensation?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.lender_paid_compensation?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Default Services You Can Shop For Fee ($) :</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    // required
                                                                    value={formData.defaultTitleCharges}
                                                                    onChange={(e) =>
                                                                        handleInputChange("defaultTitleCharges", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.default_services_you_can_shop_for_fee?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.default_services_you_can_shop_for_fee?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Default Services You Can't Shop For Fee ($) :</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    // required
                                                                    value={formData.defaultCreditAdVerificationFee}
                                                                    onChange={(e) =>
                                                                        handleInputChange("defaultCreditAdVerificationFee", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.default_services_you_cannot_shop_for_fee?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.default_services_you_cannot_shop_for_fee?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                            
                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Company NMLS:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=''
                                                                    // required
                                                                    value={formData.companyNmls}
                                                                    onChange={(e) =>
                                                                        handleInputChange("companyNmls", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.company_name?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.company_name?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Company Name:</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=''
                                                                    // required
                                                                    value={formData.companyName}
                                                                    onChange={(e) =>
                                                                        handleInputChange("companyName", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.company_name?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.company_name?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} sm="6" md="4">
                                                                <Form.Label htmlFor="inputState">Licensed States:</Form.Label>
                                                                <Multiselect
                                                                    options={states}
                                                                    selectedValues={selectedObjects}
                                                                    onSelect={handleSelect}
                                                                    onRemove={handleRemove}
                                                                    displayValue="name"
                                                                    placeholder="Choose all that apply"
                                                                    style={{
                                                                        optionContainer: {
                                                                            height: "150px",
                                                                        }
                                                                    }}
                                                                    isInvalid={errors?.licensed_states?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.licensed_states?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Default Processing Fee:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=''
                                                                    value={formData.defaultProcessingFee}
                                                                    onChange={(e) =>
                                                                        handleInputChange("defaultProcessingFee", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.default_processing_fee?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.default_processing_fee?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Underwriting Fee:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=''
                                                                    value={formData.underwritingFee}
                                                                    onChange={(e) =>
                                                                        handleInputChange("underwritingFee", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.underwriting_fee?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.underwriting_fee?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            {/* {user?.role === "admin" && <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                                <Form.Label>Cost Per Lead:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=''
                                                                    value={formData.CostPerLead}
                                                                    onChange={(e) =>
                                                                        handleInputChange("CostPerLead", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.cost_per_lead?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.cost_per_lead?.[0]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>} */}
                                                        </Row>


                                                        <Button type="submit" variant="primary">
                                                            <div
                                                                style={{
                                                                    transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                                                }}
                                                            >
                                                                <div className="spinner-border text-warning loadding-spinner mr-2"
                                                                    style={{
                                                                        opacity: isLoading ? 100 : 0,
                                                                    }}
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden"></span>
                                                                </div>

                                                                <span>Update</span>
                                                            </div>
                                                        </Button>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </ Tab.Pane>
                                    </ Tab.Content>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="chang-role" role="tabpanel">
                                            <Card>
                                                <Card.Header>
                                                    <Card.Header.Title>
                                                        <h4 className="card-title">Change Role</h4>
                                                    </Card.Header.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form onSubmit={handleChangeRole}>
                                                        <Form.Group controlId="roleSelection" className='ml-3'>
                                                            <Form.Label className='fw-bold mb-3' style={{ fontWeight: "600", fontSize: "18px" }}>Select Role:</Form.Label>
                                                            <div className='mb-5 pl-3'>
                                                                <div className="custom-control custom-radio custom-control-inline mb-3" style={{ width: "100%" }}>
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        name="userRole"
                                                                        id="manager"
                                                                        style={{
                                                                            height: "25px",
                                                                            width: "25px"
                                                                        }}
                                                                        value="mannager"
                                                                        checked={formData.role === "mannager"}
                                                                        onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))}
                                                                    />
                                                                    <Form.Check.Label htmlFor="manager" className='ml-2'> Manager </Form.Check.Label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline mb-3" style={{ width: "100%" }}>
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        name="userRole"
                                                                        id="user"
                                                                        style={{
                                                                            height: "25px",
                                                                            width: "25px"
                                                                        }}
                                                                        value="user"
                                                                        checked={formData.role === "user"}
                                                                        onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))}
                                                                    />
                                                                    <Form.Check.Label htmlFor="user" className='ml-2'> User </Form.Check.Label>
                                                                </div>
                                                                <div className="custom-control custom-radio custom-control-inline mb-3" style={{ width: "100%" }}>
                                                                    <Form.Check.Input
                                                                        type="radio"
                                                                        name="userRole"
                                                                        id="admin"
                                                                        style={{
                                                                            height: "25px",
                                                                            width: "25px"
                                                                        }}
                                                                        value="admin"
                                                                        checked={formData.role === "admin"}
                                                                        onChange={(e) => setFormData((prev) => ({ ...prev, role: e.target.value }))}
                                                                    />
                                                                    <Form.Check.Label htmlFor="guest" className='ml-2'> Admin </Form.Check.Label>
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                        {/* <Button type="reset" variant="outline-primary mr-2">Cancel</Button> */}
                                                        <Button type="submit" variant="primary">
                                                            <div
                                                                style={{
                                                                    transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                                                }}
                                                            >
                                                                <div className="spinner-border text-warning loadding-spinner mr-2"
                                                                    style={{
                                                                        opacity: isLoading ? 100 : 0,
                                                                    }}
                                                                    role="status"
                                                                >
                                                                    <span className="visually-hidden"></span>
                                                                </div>

                                                                <span>Submit</span>
                                                            </div>
                                                        </Button>
                                                    </Form>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </ div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container >

                <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
            </>
    )
}

export default UsersEdit