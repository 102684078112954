import { Button } from "react-bootstrap"


const CommonButton = ({ isLoading, onSubmit=()=>{}, text }) => {

    return (
        <Button type="submit" className="btn btn-primary mt-2" onClick={onSubmit}>
        <div
          style={{
            transform: !isLoading ? 'translateX(-1.5rem)' : 'translateX(0)',
          }}
        >
          <div className="spinner-border text-warning loadding-spinner mr-2"
            style={{
              opacity: isLoading ? 100 : 0, 
            }}
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>

            <span>{text}</span>
        </div>
      </Button>
    )
}

export default CommonButton
