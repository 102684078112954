import { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useGetGeneratedQuoteMutation } from "../../../api/leadsAPISlices";
import { Button } from "react-bootstrap";
import html2canvas from "html2canvas";

const LeadGenerateQuoteView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [error, setError] = useState(null);
    const divRef = useRef(null);

    const { leadId } = useParams();
    const token = localStorage.getItem("token");
    const options = localStorage.getItem("options");

    const [getGeneratedQuote] = useGetGeneratedQuoteMutation();

    const getQuoteViewUrl = async () => {
        if (!token || !options) {
            setError("Missing token or options in localStorage");
            return;
        }

        try {
            setIsLoading(true);
            const payload = JSON.parse(options);
            const response = await getGeneratedQuote({
                payload,
                leadId,
                token: JSON.parse(token),
            }).unwrap();
            setImageUrl(response || "");
        } catch (err) {
            console.error(err);
            setError("Failed to load the quote. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getQuoteViewUrl();
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="text-danger">{error}</div>;
    }

    const downloadImage = async () => {
        // imageUrl is base64encoded
        const canvas = await html2canvas(divRef.current);
        const url = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = url;
        a.download = leadId + '_quote.png';
        a.click();
    }

    return (
        <div>
            {imageUrl ? (
                <div className="mb-5 mt-1">
                    <div className="d-flex mb-4  justify-content-end">
                        <Button onClick={downloadImage} className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                            <i class="las la-download mr-1" style={{ fontSize: '20px' }}></i>
                            Download
                        </Button>
                        <Link to={`/lead-view/${leadId}`} className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            <span className="ml-2">Back</span>
                        </Link>
                    </div>
                    <div>
                        {/* imageUrl is base64encoded */}
                        <img ref={divRef} src={`data:image/png;base64,${imageUrl}`} alt="quote" />
                    </div>
                </div>
            ) : (
                <div className="text-muted">No image available</div>
            )}
        </div>
    );
};

export default LeadGenerateQuoteView;

