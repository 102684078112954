import { useEffect, useState } from "react";
import { useGetRateStackMutation } from "../../api/leadsAPISlices";
import Toaster from "../utility/Toaster";

const columns = [
    { label: "Rate", key: "rate" },
    { label: "Price", key: "price" }
];

const RateSack = ({ setSelectedRates, selectedRates, leadId, token, formData, handleSave, isGettingData }) => {
    const [rateStack, setRateStack] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showToaster, setShowToaster] = useState(false);
    const [toasterMessage, setToasterMessage] = useState("");
    const [toasterStatus, setToasterStatus] = useState("");

    const [getRateStack] = useGetRateStackMutation();

    const handleRowClick = (rate) => {
        const isSelected = selectedRates.some(
            (item) => item.id === rate.id && item.rate === rate.rate
        );

        if (isSelected) {
            setSelectedRates((prev) =>
                prev.filter((item) => item.id !== rate.id || item.rate !== rate.rate)
            );
        } else if (selectedRates.length < 5) {
            setSelectedRates((prev) => [...prev, rate]);
        }
    };

    const selectFiveDefault = () => {
        const length = rateStack.length;
        let defaultRates = [];

        if (length > 5) {
            defaultRates = [
                rateStack[0],
                ...rateStack.slice(Math.floor((length - 3) / 2), Math.floor((length - 3) / 2) + 3),
                rateStack[length - 1]
            ];
        } else {
            defaultRates = rateStack;
        }

        setSelectedRates(defaultRates);
    };

    const getRateData = async () => {
        try {
            const response = await getRateStack({ leadId, token }).unwrap();
            setRateStack(response);
        } catch (error) {
            setShowToaster(true);
            setToasterMessage(error?.data?.message || "Failed to fetch rate stack");
            setToasterStatus("error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // setIsLoading(true);
        handleSave().then((result) => {
            !isGettingData && getRateData();
        });
    }, [formData]);

    useEffect(() => {
        if (rateStack?.length > 0) {
            selectFiveDefault();
        }else{
            setSelectedRates([])
        }
    }, [rateStack]);

    return (
        <>
            {isLoading ?
                (<div className='text-center py-5'>
                    <div class="spinner-border text-warning mr-2" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div>)
                : rateStack.length > 0 ? (
                    <div
                        className="table-responsive border-back rounded text-center"
                        style={{ maxWidth: "600px", margin: "auto" }}
                    >
                        <table className="table data-table mb-0">
                            <thead className="table-color-heading">
                                <tr>
                                    {columns.map((col, i) => (
                                        <th key={i} scope="col">
                                            {col.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rateStack.map((row) => {
                                    const isSelected = selectedRates.some(
                                        (item) => item.id === row.id && item.rate === row.rate
                                    );

                                    return (
                                        <tr
                                            key={row.id}
                                            className={`white-space-no-wrap ${isSelected ? "table-active" : ""
                                                }`}
                                            onClick={() => handleRowClick(row)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {columns.map((col) => (
                                                <td key={col.key}>{row[col.key] || "N/A"}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>No rate stack found</p>
                )}

            <Toaster
                status={toasterStatus}
                message={toasterMessage}
                show={showToaster}
                setShow={setShowToaster}
            />
        </>
    );
};

export default RateSack;
