import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetCountiesMutation, useGetLeadMutation, useUpdateLeadMutation } from '../../../api/leadsAPISlices';
import Toaster from '../../../components/utility/Toaster';
// import counties from "../../../assets/json/county.json";
import states from "../../../assets/json/states.json";

const fromInitialValus = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    creditScore: null,
    veteran: false,
    address: "",
    city: "",
    state: "",
    zipcode: "",
    state: "",
    propertyState: '',
    propertyZip: "",
    propertyValue: null,
    propertyType: "",
    propertyoccupancy: "",
    PropertymonthlyTax: null,
    propertyMonthlyInsurance: null,
    propertyLoanProgram: "",
    propertyRevolvingDebt: null,
    propertyRevolvingPayment: null,
    propertyInstallmentDebt: null,
    propertyInstallmentPayment: null,
    currentBalance: null,
    currentRate: null,
    mortgagePayment: null,
    county: '',
}

const LeadEdit = () => {
    const [formData, setFormData] = useState(fromInitialValus);
    const [isLoading, setIsLoading] = useState(false);
    const [isHaveError, setIsHaveError] = useState(true);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [errors, setErrors] = useState();
    const [counties, setcounties] = useState([]);
    const [stateCounty, setStateCounty] = useState([]);

    const [getlead] = useGetLeadMutation();
    const [getCounties] = useGetCountiesMutation();
    const [updateLead] = useUpdateLeadMutation();

    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("token"));

    const { leadId } = useParams();

    const getCountry = async () => {
        try {
            const response = await getCounties({ token }).unwrap();

            setcounties(response)

        } catch (error) {
        }
    };

    const getLeadByID = async () => {
        try {
            const response = await getlead({ leadId, token }).unwrap();

            setFormData((prev) => ({
                ...prev,
                firstName: response?.data?.first_name,
                lastName: response?.data?.last_name,
                email: response?.data?.email,
                phone: response?.data?.phone,
                creditScore: response?.data?.credit_score,
                veteran: Boolean(response?.data?.is_veteran),
                address: response?.data?.address,
                city: response?.data?.city,
                state: response?.data?.state,
                zipcode: response?.data?.zip,
                propertyState: response?.data?.property_state,
                propertyZip: response?.data?.property_zip,
                propertyValue: response?.data?.property_value,
                propertyType: response?.data?.property_type,
                NumberOfUnits: response?.data?.number_of_units,
                propertyoccupancy: response?.data?.property_occupancy,
                PropertymonthlyTax: response?.data?.property_annual_tax,
                propertyMonthlyInsurance: response?.data?.property_monthly_insurance,
                propertyLoanProgram: response?.data?.property_current_loan_program,
                propertyRevolvingDebt: response?.data?.property_revolving_debt,
                propertyRevolvingPayment: response?.data?.property_revolving_payments,
                propertyInstallmentDebt: response?.data?.property_installment_debt,
                propertyInstallmentPayment: response?.data?.property_installment_payments,
                currentBalance: response?.data?.first_mortgage_balance,
                currentRate: response?.data?.property_current_interest_rate,
                mortgagePayment: response?.data?.first_mortgage_monthly_payment,
                county: response?.data?.county,
            }))

            setIsHaveError(false)

        } catch (error) {
            setShowToster(true)
            setTosterMassage(error?.data?.message)
            setTosterStatus('error')
            setIsHaveError(true)

            setTimeout(() => {
                navigate(`/leads`);
            }, 3000);

        }
    };

    useEffect(() => {
        getCountry()
        getLeadByID();
    }, [leadId]);

    useEffect(() => {
        const stateValue = states.find(state => state.value === formData.state)?.value;

        if (stateValue && counties?.length !== 0) {
            setStateCounty(counties?.[stateValue]);
        } else {
            setStateCounty([]);
        }
    }, [counties, formData.state]);

    const handleInputChange = (field, value) => {

        if (field === 'propertyType') {
            if (value === 'Single Family' || value === 'Condo' || value === 'Townhouse') {
                setFormData((prev) => ({
                    ...prev,
                    NumberOfUnits: 1
                }));
            } else if (value === 'Duplex') {
                setFormData((prev) => ({
                    ...prev,
                    NumberOfUnits: 2
                }));
            } else if (value === 'Triplex') {
                setFormData((prev) => ({
                    ...prev,
                    NumberOfUnits: 3
                }));
            } else if (value === 'Quadruplex') {
                setFormData((prev) => ({
                    ...prev,
                    NumberOfUnits: 4
                }));
            }
        }

        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true)

        const payload = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            credit_score: formData.creditScore,
            is_veteran: Boolean(formData.veteran),
            address: formData.address,
            city: formData.city,
            state: formData.state,
            zip: formData.zipcode,
            property_state: formData.propertyState,
            property_zip: formData.propertyZip,
            property_value: formData.propertyValue,
            property_type: formData.propertyType,
            number_of_units: formData.NumberOfUnits,
            property_occupancy: formData.propertyoccupancy,
            property_annual_tax: formData.PropertymonthlyTax,
            property_monthly_insurance: formData.propertyMonthlyInsurance,
            property_current_loan_program: formData.propertyLoanProgram,
            property_revolving_debt: formData.propertyRevolvingDebt,
            property_revolving_payments: formData.propertyRevolvingPayment,
            property_installment_debt: formData.propertyInstallmentDebt,
            property_installment_payments: formData.propertyInstallmentPayment,
            first_mortgage_balance: formData.currentBalance,
            property_current_interest_rate: formData.currentRate,
            first_mortgage_monthly_payment: formData.mortgagePayment,
            county: formData?.county,
        }

        await updateLead({ payload, leadId, token })
            .unwrap()
            .then((res) => {
                setErrors([])
                navigate(`/lead-edit-quote/${leadId}`);

                setIsLoading(false)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setErrors(error?.data?.errors)

                setIsLoading(false)
            })
    };

    return (
        <>
            {!isHaveError &&
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/leads">Lead</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Lead</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/leads" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-2 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Edit Lead</h4>
                        </Col>


                        <Form onSubmit={handleSubmit}>

                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <Card.Body>
                                            <div className='d-flex align-items-center mb-3'>
                                                <h4 className="font-weight-bold text-center pb-1 border-bottom border-primary m-auto" style={{ fontSize: '21px' }}>Borrower Details</h4>
                                            </div>

                                            <div className='row g-2 px-1'>
                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group controlId="fname">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            // className="form-control"
                                                            placeholder="Enter First Name"
                                                            required
                                                            value={formData.firstName}
                                                            onChange={(e) =>
                                                                handleInputChange("firstName", e.target.value)
                                                            }
                                                            isInvalid={errors?.first_name && errors?.first_name?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.first_name?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group controlId="lastname">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Last Name"
                                                            // className="form-control"
                                                            value={formData.lastName}
                                                            onChange={(e) =>
                                                                handleInputChange("lastName", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.last_name && errors?.last_name?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.last_name?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter Email"
                                                            // className="form-control"
                                                            value={formData.email}
                                                            onChange={(e) =>
                                                                handleInputChange("email", e.target.value)
                                                            }
                                                            isInvalid={errors?.email && errors?.email?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.email?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group>
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control
                                                            type="phone"
                                                            className="form-control"
                                                            placeholder="Enter Phone"
                                                            value={formData.phone}
                                                            onChange={(e) =>
                                                                handleInputChange("phone", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.phone && errors?.phone?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.phone?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group>
                                                        <Form.Label>Credit Score</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter Credit Score"
                                                            value={formData.creditScore}
                                                            onChange={(e) =>
                                                                handleInputChange("creditScore", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.credit_score && errors?.credit_score?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.credit_score?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">

                                                    <Form.Group>
                                                        <Form.Label>Veteran</Form.Label>
                                                        <select
                                                            className="form-select"
                                                            value={formData.veteran}
                                                            onChange={(e) =>
                                                                handleInputChange("veteran", e.target.value)
                                                            }
                                                            isInvalid={errors?.is_veteran && errors?.is_veteran?.length > 0}
                                                        >
                                                            <option value="No">No</option>
                                                            <option value="Yes">Yes</option>
                                                        </select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.is_veteran?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group>
                                                        <Form.Label>Address</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Address"
                                                            value={formData.address}
                                                            onChange={(e) =>
                                                                handleInputChange("address", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.address && errors?.address?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.address?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-0 px-1">
                                                    <Form.Group>
                                                        <Form.Label htmlFor="inputState">City</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter city"
                                                            value={formData.city}
                                                            onChange={(e) =>
                                                                handleInputChange("city", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.city && errors?.city?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.city?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-2 px-1">
                                                    <Form.Group>
                                                        <Form.Label htmlFor="inputState">State/Region</Form.Label>
                                                        <select
                                                            id="inputState"
                                                            className="form-select"
                                                            value={formData.state}
                                                            onChange={(e) =>
                                                                handleInputChange("state", e.target.value)
                                                            }
                                                            isInvalid={errors?.state && errors?.state?.length > 0}
                                                        >
                                                            {states.map((option) => (
                                                                <option value={option.value}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.state?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-2 px-1">
                                                    <Form.Group>
                                                        <Form.Label htmlFor="inputState">County</Form.Label>
                                                        <select
                                                            id="inputState"
                                                            className="form-select"
                                                            value={formData.county}
                                                            onChange={(e) =>
                                                                handleInputChange("county", e.target.value)
                                                            }
                                                            isInvalid={errors?.county && errors?.county?.length > 0}
                                                        >
                                                            {stateCounty.map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.state?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-6 col-lg-3 mb-2 px-1">
                                                    <Form.Group>
                                                        <Form.Label

                                                        >
                                                            Zip code
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Enter Zip code"
                                                            value={formData.zipcode}
                                                            onChange={(e) =>
                                                                handleInputChange("zipcode", e.target.value)
                                                            }
                                                            required
                                                            isInvalid={errors?.zip && errors?.zip?.length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.zip?.[0]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {/*  Subject Property Details */}
                                <Col lg="12">
                                    <Card>
                                        <Card.Body>
                                            <div className='d-flex align-items-center mb-3'>
                                                <h4 className="font-weight-bold text-center pb-1 border-bottom border-primary m-auto" style={{ fontSize: '21px' }}>Subject Property Details</h4>
                                            </div>

                                            <Row>
                                                <Col md="12">
                                                    {/* <div className='row g-3'>
                                                    <div className="col-md-6 col-lg-3 mb-2">
                                                        <Form.Label htmlFor="inputState">State/Region</Form.Label>
                                                        <select
                                                            id="inputState"
                                                            className="form-select"
                                                            value={formData.propertyState}
                                                            onChange={(e) =>
                                                                handleInputChange("propertyState", e.target.value)
                                                            }
                                                        >
                                                            {states.map((option) => (
                                                                <option value={option.value}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="col-md-6 col-lg-3 mb-2">
                                                        <Form.Label htmlFor="Text7">Zip code</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="Text7"
                                                            className="form-control"
                                                            placeholder="Enter Zip code"
                                                            value={formData.propertyZip}
                                                            onChange={(e) =>
                                                                handleInputChange("propertyZip", e.target.value)
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </div> */}

                                                    <hr style={{ borderTop: "1px solid rgb(0 0 0 / 92%)", margin: "14px 0" }} />

                                                    <div className='row g-3'>
                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="propertyValue">Property Value</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="propertyValue"
                                                                    placeholder="0"
                                                                    value={formData.propertyValue}
                                                                    onChange={(e) => handleInputChange("propertyValue", e.target.value)}
                                                                    required
                                                                    isInvalid={!!errors?.property_value?.length}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_value?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>


                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="propertyType">Property Type</Form.Label>
                                                            <select
                                                                id="propertyType"
                                                                className="form-select"
                                                                value={formData.propertyType}
                                                                onChange={(e) =>
                                                                    handleInputChange("propertyType", e.target.value)
                                                                }
                                                                isInvalid={errors?.property_type && errors?.property_type?.length > 0}
                                                            >
                                                                <option className="">-</option>
                                                                <option value="Single Family">Single Family</option>
                                                                <option value="Condo">Condo</option>
                                                                <option value="Townhouse">Townhouse</option>
                                                                <option value="Duplex">Duplex</option>
                                                                <option value="Triplex">Triplex</option>
                                                                <option value="Quadruplex">Quadruplex</option>
                                                            </select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.property_type?.[0]}
                                                            </Form.Control.Feedback>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="numberofUnits">Number of Units</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="numberofUnits"
                                                                    disabled={true}
                                                                    value={formData.NumberOfUnits}
                                                                    onChange={(e) =>
                                                                        handleInputChange("NumberOfUnits", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.number_of_units && errors?.number_of_units?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.number_of_units?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="Occupancy">Occupancy</Form.Label>
                                                            <select
                                                                id="Occupancy"
                                                                className="form-select"
                                                                value={formData.propertyoccupancy}
                                                                onChange={(e) =>
                                                                    handleInputChange("propertyoccupancy", e.target.value)
                                                                }
                                                                isInvalid={errors?.property_occupancy && errors?.property_occupancy?.length > 0}
                                                            >
                                                                <option className="">-</option>
                                                                <option value="Primary">Primary</option>
                                                                <option value="Secondary Home">Secondary Home</option>
                                                                <option value="Investment">Investment</option>
                                                            </select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.property_occupancy?.[0]}
                                                            </Form.Control.Feedback>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="monthlyPropertyTax">Annual Property Tax</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="monthlyPropertyTax"
                                                                    placeholder="0"
                                                                    value={formData.PropertymonthlyTax}
                                                                    onChange={(e) =>
                                                                        handleInputChange("PropertymonthlyTax", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_annual_tax && errors?.property_annual_tax?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_annual_tax?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="monthlyInsurance">Monthly Insurance</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="monthlyInsurance"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    value={formData.propertyMonthlyInsurance}
                                                                    onChange={(e) =>
                                                                        handleInputChange("propertyMonthlyInsurance", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_monthly_insurance && errors?.property_monthly_insurance?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_monthly_insurance?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="loanProgram">Current Loan Program</Form.Label>
                                                            <select
                                                                id="loanProgram"
                                                                className="form-select"
                                                                value={formData.propertyLoanProgram}
                                                                onChange={(e) =>
                                                                    handleInputChange("propertyLoanProgram", e.target.value)
                                                                }
                                                                isInvalid={errors?.property_current_loan_program && errors?.property_current_loan_program?.length > 0}
                                                            >
                                                                <option value="Conventional">Conventional</option>
                                                                <option value="FHA">FHA</option>
                                                                <option value="VA">VA</option>
                                                            </select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.property_current_loan_program?.[0]}
                                                            </Form.Control.Feedback>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="monthlyInsurance">Current Balance</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="currentBalance"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    value={formData.currentBalance}
                                                                    onChange={(e) =>
                                                                        handleInputChange("currentBalance", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.first_mortgage_balance && errors?.first_mortgage_balance?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.first_mortgage_balance?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="monthlyInsurance">Current Rate</Form.Label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="currentRate"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    value={formData.currentRate}
                                                                    onChange={(e) =>
                                                                        handleInputChange("currentRate", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.property_current_interest_rate && errors?.property_current_interest_rate?.length > 0}
                                                                />
                                                                <InputGroup.Text>%</InputGroup.Text>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_current_interest_rate?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="monthlyInsurance">Mortgage Payment</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="mortgagePayment"
                                                                    className="form-control"
                                                                    placeholder="0"
                                                                    value={formData.mortgagePayment}
                                                                    onChange={(e) =>
                                                                        handleInputChange("mortgagePayment", e.target.value)
                                                                    }
                                                                    isInvalid={errors?.first_mortgage_monthly_payment && errors?.first_mortgage_monthly_payment?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.first_mortgage_monthly_payment?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>
                                                    </div>

                                                    <hr style={{ borderTop: "1px solid rgb(0 0 0 / 92%)", margin: "14px 0" }} />

                                                    <div className='row g-3'>
                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="revolvingDebt">Revolving Debt</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="revolvingDebt"
                                                                    className="form-control"
                                                                    value={formData.propertyRevolvingDebt}
                                                                    onChange={(e) =>
                                                                        handleInputChange("propertyRevolvingDebt", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_revolving_debt && errors?.property_revolving_debt?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_revolving_debt?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="revolvingPayment">Revolving Payment</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="revolvingPayment"
                                                                    className="form-control"
                                                                    value={formData.propertyRevolvingPayment}
                                                                    onChange={(e) =>
                                                                        handleInputChange("propertyRevolvingPayment", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_revolving_payments && errors?.property_revolving_payments?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_revolving_payments?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="installmentDebt">Installment Debt</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="installmentDebt"
                                                                    className="form-control"
                                                                    value={formData.propertyInstallmentDebt}
                                                                    onChange={(e) =>
                                                                        handleInputChange("propertyInstallmentDebt", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_installment_debt && errors?.property_installment_debt?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_installment_debt?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3 mb-2">
                                                            <Form.Label htmlFor="installmentPayment">Installment Payment</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="installmentPayment"
                                                                    className="form-control"
                                                                    value={formData.propertyInstallmentPayment}
                                                                    onChange={(e) =>
                                                                        handleInputChange("propertyInstallmentPayment", e.target.value)
                                                                    }
                                                                    required
                                                                    isInvalid={errors?.property_installment_payments && errors?.property_installment_payments?.length > 0}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors?.property_installment_payments?.[0]}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <div className="d-flex flex-wrap justify-content-end mt-0 mb-5">
                                <Button type="submit" variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                                    <div
                                        style={{
                                            transform: !isLoading ? 'translateX(-1.2rem)' : 'translateX(0)'
                                        }}
                                    >
                                        <div className="spinner-border text-warning loadding-spinner mr-2"
                                            style={{
                                                opacity: isLoading ? 100 : 0,
                                            }}
                                            role="status"
                                        >
                                            <span className="visually-hidden"></span>
                                        </div>

                                        <span>Submit</span>
                                    </div>
                                </Button>
                            </div>
                        </Form>
                    </Row>
                </Container>
            }

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />

        </>
    )
}

export default LeadEdit;
