import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useAddPaymentMethodsMutation } from "../../api/paymentAPISlices";
import Toaster from "../utility/Toaster";

const cardElementStyle = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            letterSpacing: '0.025em',
            fontFamily: 'Arial, sans-serif',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

const SaveCard = ({ setShow, getCardList }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'))
    const token = JSON.parse(localStorage.getItem('token'))

    const [addPaymentMethods] = useAddPaymentMethodsMutation();

    const handleSaveCard = async () => {
        const cardElement = elements.getElement(CardElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                email: user?.email,
                name: `${user?.first_name} ${user?.last_name}`,
                phone: user?.name
            },
        });
        
        if (paymentMethod?.id) {
            addPaymentMethod(paymentMethod?.id)
        }else{

            setShowToster(true)
            setTosterMassage("Please use a different card.")
            setTosterStatus('error')
        }

    };

    const addPaymentMethod = async (id) => {
        setIsLoading(true)

        const payload = {
            payment_method_id: id
        }

        await addPaymentMethods({ payload, token })
            .unwrap()
            .then((res) => {
                setShow(false)
                setIsLoading(false)
                getCardList()

                setShowToster(true)
                setTosterMassage("New card added")
                setTosterStatus('success')
            })
            .catch((error) => {

                setIsLoading(false)

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
            })
    }

    return (
        <div className="pt-5">
            <CardElement options={cardElementStyle} />
            <button onClick={handleSaveCard} disabled={!stripe} className="btn btn-primary position-relative d-flex align-items-center justify-content-between mt-5" >
                <div
                    style={{
                        transform: !isLoading ? 'translateX(-1.5rem)' : 'translateX(0)'
                    }}
                >
                    <div className="spinner-border text-warning loadding-spinner mr-2"
                        style={{
                            opacity: isLoading ? 100 : 0,
                        }}
                        role="status"
                    >
                        <span className="visually-hidden"></span>
                    </div>

                    <span>Save Payment Method</span>
                </div>
            </button>

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
        </div>
    );
};

export default SaveCard;
