import { useState } from 'react';
import { Toast } from 'react-bootstrap';

const Toaster = ({ status = "success", message , show, setShow}) => {

    return (
        <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={4000}
            autohide
            className={`${status === 'error' ? `bg-danger` : `bg-success`} toaster-position`}
            style={{position: "fixed", bottom: '30px', left: "40%", zIndex: "999999"}}
        >
            <Toast.Header style={{ padding: "0 10px" }}>
                <strong className="me-auto text-capitalize">{status}</strong>
            </Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    );
};

export default Toaster
