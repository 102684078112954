import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { useLoginAuthenticMutation } from "../../../api/authApiSlices";
import Toaster from "../../../components/utility/Toaster";

// import s from "../../../assets"
import logo from '../../../assets/images/logo.webp'

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const initialValus = {
  email: '',
  password: '',
}

const SignIn = (props) => {
  const [formData, setFormData] = useState(initialValus);
  const [isLoading, setIsLoading] = useState(false);
  const [showToster, setShowToster] = useState(false);
  const [tosterMassage, setTosterMassage] = useState(false);
  const [tosterStatus, setTosterStatus] = useState(false);

  const [loginAuthentic] = useLoginAuthenticMutation();

  const navigate = useNavigate();

  const token = '';

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)

    const payload = {
      email: formData.email,
      password: formData.password,
      token: token
    }

    await loginAuthentic(formData)
      .unwrap()
      .then((res) => {
        localStorage.setItem("token", JSON.stringify(res?.token));

        navigate("/");
        setIsLoading(false)
      })
      .catch((error) => {

        setShowToster(true)
        setTosterMassage(error?.data?.message)
        setTosterStatus('error')

        setIsLoading(false)
      });
  };

  return (
    <>
      <section className="login-content">
        <Container className="h-100">


          {/* <Card className="p-3 pt-0"> */}
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-3 pt-0">
                <Card.Body className="pt-1">
                  <div>
                    <img src={logo} className={`img-fluid big-logo rounded-normal light-logo mb-5`} alt="logo" />
                  </div>

                  {/* <h3 className="mb-3 font-weight-bold text-center">Sign In</h3> */}

                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col lg="12">
                        <Form.Group controlId="email">
                          <Form.Label
                            className="text-secondary"
                          >
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            required
                            value={formData.email}
                            onChange={(e) =>
                              handleInputChange("email", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <Form.Group>
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-secondary">
                              Password
                            </Form.Label>
                            <Form.Label>
                              <Link to="/auth/recoverpw">Forgot Password?</Link>
                            </Form.Label>
                          </div>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            required
                            value={formData.password}
                            onChange={(e) =>
                              handleInputChange("password", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button type="submit" className="btn btn-primary btn-block mt-2">
                      <div
                        style={{
                          transform: !isLoading ? 'translateX(-1.5rem)' : 'translateX(0)',
                        }}
                      >
                        <div className="spinner-border text-warning loadding-spinner mr-2"
                          style={{
                            opacity: isLoading ? 100 : 0, 
                          }}
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>

                        <span>Log In</span>
                      </div>
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md="5">
              <Card className="p-3 pt-0">
                <Card.Body className="pt-1">
              
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          {/* </ Card> */}
        </Container>
      </section>

      <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
    </>
  );
};

export default connect(mapStateToProps)(SignIn);
