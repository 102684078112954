import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

const LeadeView = () => {
    const previewData = JSON.parse(localStorage.getItem("preview"));
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { leadId } = useParams();

    const handleAdjust = () => {
        navigate(`/lead-edit-quote/${leadId}`);
    }

    const handleAccept = () => {
        setIsLoading(true)
        navigate(`/lead-generat-quoted-view/${leadId}`);
    }

    return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb p-0 mb-0">
                                    <li className="breadcrumb-item"><Link to="/leads">Lead > Preview</Link></li>
                                </ol>
                            </nav>
                        </div>
                        <Link to={`/lead-edit-quote/${leadId}`} className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            <span className="ml-2">Back</span>
                        </Link>
                    </div>
                </Col>

                <Col sm={12}>
                    <p className='text-center' style={{ fontSize: '17px' }}>
                        <strong>Lead ID</strong>: {leadId}
                        {/* <strong className='ml-3'>Lead Name</strong>: Fahim Joy */}
                    </p>
                </Col>

                <div className="table-responsive mb-5 border rounded">
                    <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                            <tr className="">
                                <th scope="col">

                                </th>
                                <th scope="col">
                                    Option 1
                                </th>
                                <th scope="col">
                                    Option 2
                                </th>
                                <th scope="col">
                                    Option 3
                                </th>
                                <th scope="col">
                                    Option 4
                                </th>
                                <th scope="col">
                                    Option 5
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan Type</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {data?.loan_type || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Term</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {data?.term || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Rate</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {data?.rate + '%' || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan Amount</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.loan_amount) || "N/A"}
                                    </td>
                                ))}
                            </ tr>


                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>LTV</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.ltv) || "N/A"}%
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>APR</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {data?.apr+'%' || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            {/* <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan-to-Value</strong>
                                </td>

                                <td>
                                    {previewData?.[0]?.apr || "N/A"}
                                </td>
                                <td>
                                    {previewData?.[1]?.apr || "N/A"}
                                </td>
                                <td>
                                    {previewData?.[2]?.apr || "N/A"}
                                </td>
                                <td>
                                    {previewData?.[3]?.apr || "N/A"}
                                </td>
                                <td>
                                    {previewData?.[4]?.apr || "N/A"}
                                </td>
                            </ tr> */}

                            <tr className="white-space-no-wrap">
                                <td className='text-center' colSpan={6} style={{ background: "#f1f4ff" }}>
                                    <strong>Monthly Payment</strong>
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Principal & Interest</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.principal_and_interest) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>PMI</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.pmi) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Estimated Escrow</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.estimated_escrow) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Estimated Payment</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.estimated_payment) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td className='text-center' colSpan={6} style={{ background: "#f1f4ff" }}>
                                    <strong>Cash Out Details</strong>
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Rate Cost or Credit</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.rate_cost_or_credit) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Esrow Reserves</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.escrow_reserves) || "N/A"}
                                    </td>
                                ))}
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Cash to You</strong>
                                </td>

                                {previewData?.map((data, index) => (
                                    <td key={index}>
                                        {new Intl.NumberFormat('en-US').format(data?.cash_to_you) || "N/A"}
                                    </td>
                                ))}
                            </ tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive mb-5 border rounded">
                    <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                            <th className='text-center' scope="col" colSpan={2}>
                                Lender & Third Party Fees
                            </th>
                            <th className='text-center' scope="col" colSpan={2}>
                                Loan Details
                            </th>
                        </thead>
                        <tbody>
                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Underwriting</strong>
                                </td>
                                <td >
                                    {previewData?.[0]?.underwriting || 'Waived'}
                                </td>
                                <td className=''>
                                    <strong>Property Value</strong>
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US').format(previewData?.[0]?.property_value) || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Processing</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.processing || 'N/A'}
                                </td>
                                <td>
                                    <strong>Current Balance</strong>
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US').format(previewData?.[0]?.current_balance) || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Services You Can Shop For</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.services_you_can_shop_for_fee || 'Waived'}
                                </td>
                                <td>
                                    <strong>State</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.state || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Services You Can't Shop For</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.services_you_cannot_shop_for_fee || 'Waived'}
                                </td>
                                <td>
                                    <strong>Loan Purpose</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.loan_purpose || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Lender and Third Party Fees</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.lender_and_third_party_fees || 'N/A'}
                                </td>
                                <td>
                                    <strong>Lock Term</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.lock_term || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    <strong>Occupancy</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.occupancy || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                   
                                </td>
                                <td>
                                    
                                </td>
                                <td>
                                    <strong>Property Type</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.property_type || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong></strong>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <strong>Escrow Waiver</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.escrow_waiver || 'N/A'}
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong></strong>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <strong>Credit Score</strong>
                                </td>
                                <td>
                                    {previewData?.[0]?.credit_score || 'N/A'}
                                </td>
                            </ tr>
                        </tbody>
                    </ table>
                </ div>

                <div className='d-flex justify-content-end mb-5'>
                    <Button onClick={handleAdjust} variant="outline-primary  font-weight-bold btn-sm py-2 px-3 mr-2">
                        Adjust
                    </Button>

                    <Button onClick={handleAccept} variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                        <div
                            style={{
                                transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                            }}
                        >
                            <div className="spinner-border text-warning loadding-spinner mr-2"
                                style={{
                                    opacity: isLoading ? 100 : 0,
                                }}
                                role="status"
                            >
                                <span className="visually-hidden"></span>
                            </div>

                            <span>Accept</span>
                        </div>
                    </Button>
                </div>

            </ Row>
        </ Container>
    )
}

export default LeadeView;
