import { apiBaseSlices } from "./baseAPI";

const token = JSON.parse(localStorage.getItem("token"));

export const authApi = apiBaseSlices.injectEndpoints({
    endpoints: (builder) => ({
        registerUser: builder.mutation({
            query: ({ payload, token }) => ({
                url: "users",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                body: payload,
            }),
        }),

        loginAuthentic: builder.mutation({
            query: (loginInfo) => ({
                url: `auth/login`,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                body: loginInfo,
            }),
        }),

        forgotPassword: builder.mutation({
            query: (email) => ({
                url: `auth/forgot-password`,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                body: { email: email },
            }),
        }),

        resetPassword: builder.mutation({
            query: (body) => ({
                url: `auth/reset-password`,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                },
                body: body,
            }),
        }),

        logout: builder.mutation({
            query: (token) => ({
                url: `auth/logout`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        getProfile: builder.mutation({
            query: (token1) => {
                return {
                    url: `auth/me`,
                    method: "GET",
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token1}`
                    }
                }
            },
        }),
    }),
});

export const {
    useRegisterUserMutation,
    useLoginAuthenticMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useLogoutMutation,
    useGetProfileMutation
} = authApi;
