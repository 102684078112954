import { apiBaseSlices } from "./baseAPI";

export const paymentAPISlices = apiBaseSlices.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentMethods: builder.mutation({
            query: (token) => ({
                url: `payment-methods`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        addPaymentMethods: builder.mutation({
            query: ({ payload, token}) => ({
                url: "payment-methods",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        deletePaymentMethods: builder.mutation({
            query: ({ Id, token }) => ({
                url: `payment-methods/${Id}`,
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        defaultPaymentMethod: builder.mutation({
            query: ({ Id, token }) => ({
                url: `payment-methods/${Id}/default`,
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),
    }),
});

export const {
    useGetPaymentMethodsMutation,
    useAddPaymentMethodsMutation,
    useDeletePaymentMethodsMutation,
    useDefaultPaymentMethodMutation,
} = paymentAPISlices;
