import { retry } from "@reduxjs/toolkit/query"
import { Card, Col, Row } from "react-bootstrap"

const BalanceCard = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <Card className="p-3 shadow-sm bg-info-subtle shadow-lg border border-light mb-3">
            <Card.Body className="p-0">
                <p className="text-mute mb-1 fs-6">Balance</p>
                <h2 className="fw-bold mb-0">
                    {user?.credit_balance || 0} <span className="fs-6 fw-normal text-muted">USD</span>
                </h2>
            </Card.Body>
        </Card>
    )
}

export default BalanceCard
