import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

//datepicker
import Datepickers from "../../../components/Datepicker";

//circularpro
import "react-circular-progressbar/dist/styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useDashboardMutation } from "../../../api/usersAPISlices";


const Dashbord = () => {
  const [categories, setCategories] = useState([])
  const [leads, setleads] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [totalLeads, setTotalLeads] = useState(0)

  const [dashboard] = useDashboardMutation()

  const token = JSON.parse(localStorage.getItem("token"))
  const user = JSON.parse(localStorage.getItem("user"))

  const chart1 = {
    options: {
      colors: ["#1f1f7a", "#2e2eb8"],
      chart: {
        fontFamily: "DM Sans",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 0.2,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        labels: {
          offsetY: 0,
          minWidth: 20,
          maxWidth: 20,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          minHeight: 20,
          maxHeight: 20,
        },
        categories:
          categories,
        // [
        //   // "2018-09-1",
        //   "09/2",
        //   "09/3",
        //   "09/4",
        //   "09/5",
        //   "09/6",
        //   "09/7",
        //   "09/8",
        //   "09/9",
        //   "09/10",
        //   "09/11",
        //   "09/12",
        //   "09/13",
        //   "09/14",
        //   "09/15",
        //   "09/16",
        //   "09/17",
        //   "09/18",
        //   "09/19",
        //   "09/20",
        //   "09/21",
        //   "09/22",
        // ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
    series: [
      // {
      //   name: "Incomes",
      //   data: [90, 105, 72, 90, 65, 109, 130,90, 105, 72, 90, 65, 109, 130,90, 105, 72, 90, 65, 109, 130],
      // },
      {
        name: "Leads",
        data: leads,
        // [115, 93, 75, 102, 144, 52, 71,115, 93, 75, 102, 144, 52, 71,115, 93, 75, 102, 144, 52, 71],
      },
    ],
  };

  const getLeadReport = async () => {
    setIsLoading(true)

    const payload = {
      start_date: startDate,
      end_date: endDate
    }

    await dashboard({ payload, token })
      .unwrap()
      .then((res) => {
        setCategories(res?.graph?.[0]?.data)
        setleads(res?.graph?.[1]?.data)
        setTotalLeads(res?.total)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoading(false)
      })
  }

  const byDefulat = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 15);

    // Set the start and end dates
    setStartDate(sevenDaysAgo.toISOString().slice(0, 10));
    setEndDate(today.toISOString().slice(0, 10));
  };

  useEffect(() => {
    byDefulat()
    getLeadReport()
  }, [startDate, endDate])

  const handleOneWeek = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    // Set the start and end dates
    setStartDate(sevenDaysAgo.toISOString().slice(0, 10));
    setEndDate(today.toISOString().slice(0, 10));
  };

  const handleOneMonth = () => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    // Set the start and end dates
    setStartDate(oneMonthAgo.toISOString().slice(0, 10));
    setEndDate(today.toISOString().slice(0, 10));
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <Row className="d-flex flex-wrap justify-content-between align-items-center" style={{justifyContent: 'space-between'}}>
            <Col lg="4">
              <h4 className="font-weight-bold">Overview</h4>
            </Col>

            <Col lg="8" className="mt-3 mt-lg-0">
              <Row style={{justifyContent: 'end'}}>
                <Col lg="6" md="5" className="mb-3 mb-md-0">
                  <div className="d-flex justify-content-start justify-content-md-end">
                    <Button className="mr-1 py-1 cursor-pointer" onClick={handleOneWeek}>7 days</Button>
                    <Button className="mr-2 py-1" onClick={handleOneMonth}>30 days</Button>
                  </div>
                </Col>
                <Col lg="6" md="7">
                  <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
                    <div className="date-icon-set">
                      <input
                        type="date"
                        name="startDate"
                        className={`form-control start data`}
                        placeholder='start date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <span className="flex-grow-0">
                      <span className="btn">To</span>
                    </span>

                    <div className="date-icon-set">
                      <input
                        type="date"
                        name="startDate"
                        className={`form-control start data`}
                        placeholder='start date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col lg="8" md="12">
          {isLoading ?
            <div className='text-center mt-5 pt-5'>
              <div className="spinner-border text-warning mr-2" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
            : <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <h4 className="font-weight-bold">Leads Report</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="primary"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#3378FF"
                        />
                      </svg>
                      <span>Leads</span>
                    </div>
                    {/* <div className="ml-3">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        fill="#19b3b3"
                      />
                    </svg>
                    <span>Leads</span>
                  </div> */}
                  </div>
                </div>
                <Chart
                  className="custom-chart"
                  options={chart1.options}
                  series={chart1.series}
                  type="area"
                  height="265"
                />
              </Card.Body>
            </Card>
          }
        </Col>

        <Col lg="4" md="12">
          <Row>
            <Col md="12">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">New Lead</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">{totalLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Current Balance</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">${user?.credit_balance}</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashbord;
