import { Form, InputGroup } from "react-bootstrap"

const LoanDetails = ({ formData, setFormData, errors , defaultPersentage}) => {

    const handleInputChange = (field, value) => {
        if (field === "compensationType", value === "Lender Paid") {
            setFormData((prev) => ({
                ...prev,
                "BPS": defaultPersentage,
                "revenueType": "Percentage"
            }));
        }
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handlePerchantgeChange = (field, value) => {
        if (formData.revenueType === "Dollar Amount") {
            setFormData((prev) => ({
                ...prev,
                "revenueDollar": value,
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    }

    return (
        <Form className=" date-icon-set-modal">
            <div className='row g-3'>
                <div className='col-md-4 col-lg-3 mb-2'>
                    <Form.Label htmlFor="lonePurpose" className="font-weight-bold">Loan Purpose</Form.Label>
                    <select
                        id="lonePurpose"
                        className="form-select"
                        value={formData.lonePurpose}
                        onChange={(e) =>
                            handleInputChange("lonePurpose", e.target.value)
                        }
                        isInvalid={errors?.loan_purpose && errors?.loan_purpose?.length > 0}
                    >
                        <option className="" disabled>Select Country</option>
                        <option value="Refinance">Refinance</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.loan_purpose?.[0]}
                    </Form.Control.Feedback>
                </div>
            </div>

            <div className='row g-3'>
                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="loneProgram" className="font-weight-bold">Loan Program</Form.Label>
                    <select
                        id="loneProgram"
                        className="form-select"
                        value={formData.loneProgram}
                        onChange={(e) =>
                            handleInputChange("loneProgram", e.target.value)
                        }
                        isInvalid={errors?.loan_program && errors?.loan_program?.length > 0}
                    >
                        <option className="" disabled>Select Loan Program</option>
                        {/* { formData?.creditScore > 620 && <option value="Conventional" >Conventional</option>} */}
                        <option value="FHA">FHA</option>
                        <option value="Conventional">Conventional</option>
                        <option value="VA">VA</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.loan_program?.[0]}
                    </Form.Control.Feedback>
                </div>

                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="financeTearm" className="font-weight-bold">Finance Term</Form.Label>
                    <select
                        id="financeTearm"
                        className="form-select"
                        value={formData.financeTearm}
                        onChange={(e) =>
                            handleInputChange("financeTearm", e.target.value)
                        }
                        isInvalid={errors?.finance_term && errors?.finance_term?.length > 0}
                    >
                        <option className="" disabled>Select Finance Term</option>
                        <option value="30yr Fixed">30yr Fixed</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.finance_term?.[0]}
                    </Form.Control.Feedback>
                </div>

                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="lender" className="font-weight-bold">Lender</Form.Label>
                    <select
                        id="lender"
                        className="form-select"
                        value={formData.lender}
                        onChange={(e) =>
                            handleInputChange("lender", e.target.value)
                        }
                        isInvalid={errors?.lender && errors?.lender?.length > 0}
                    >
                        <option className="" disabled>Select Lender</option>
                        <option value="UWM">UWM</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.lender?.[0]}
                    </Form.Control.Feedback>
                </div>

                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="baseLoanAmount" className="font-weight-bold">Base Loan Amount</Form.Label>
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            type="number"
                            id="baseLoanAmount"
                            placeholder="Enter Last Name"
                            value={formData.baseLoanAmount}
                            onChange={(e) =>
                                handleInputChange("baseLoanAmount", e.target.value)
                            }
                            isInvalid={errors?.base_loan_amount && errors?.base_loan_amount?.length > 0}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.base_loan_amount?.[0]}
                        </Form.Control.Feedback>
                    </InputGroup>
                </div>

                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="cashOut" className="font-weight-bold">
                        Cash Out
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            type="number"
                            id="cashOut"
                            placeholder="Enter Cash Out"
                            value={formData.cashOut}
                            onChange={(e) => handleInputChange("cashOut", e.target.value)}
                            isInvalid={errors?.cash_out && errors?.cash_out?.length > 0}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.cash_out?.[0]}
                        </Form.Control.Feedback>
                    </InputGroup>
                </div>


                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="reserveMounts" className="font-weight-bold">Reserve Months</Form.Label>
                    <Form.Control
                        type="number"
                        id="reserveMounts"
                        placeholder="Enter Reserve Mounts"
                        value={formData.reserveMounts}
                        onChange={(e) =>
                            handleInputChange("reserveMounts", e.target.value)
                        }
                        isInvalid={errors?.reserve_months && errors?.reserve_months?.length > 0}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.reserve_months?.[0]}
                    </Form.Control.Feedback>
                </div>
            </div>

            <hr style={{ borderTop: "1px solid rgb(0 0 0 / 92%)", margin: "14px 0" }} />

            <div className='row g-3'>
                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="revenueType" className="font-weight-bold">Revenue Type</Form.Label>
                    <select
                        disabled={formData.compensationType === "Lender Paid"}
                        id="revenueType"
                        className="form-select"
                        value={formData.revenueType}
                        onChange={(e) =>
                            handleInputChange("revenueType", e.target.value)
                        }
                        isInvalid={errors?.revenue_type && errors?.revenue_type?.length > 0}
                    >
                        <option className="">Select Revenue Type</option>
                        <option value="Percentage">Percentage</option>
                        <option value="Dollar Amount">Dollar Amount</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.revenue_type?.[0]}
                    </Form.Control.Feedback>
                </div>

                {formData.revenueType === "Percentage" && (<div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label className="font-weight-bold">
                        Select
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>%</InputGroup.Text>

                        <Form.Control
                            disabled={formData.compensationType === "Lender Paid"}
                            type="number"
                            id="BPS"
                            placeholder="Enter"
                            value={formData?.BPS}
                            onChange={(e) =>
                                handlePerchantgeChange("BPS", e.target.value)
                            }
                        />
                    </InputGroup>
                </div>)}
                {formData.revenueType !== "Percentage" && (<div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label className="font-weight-bold">
                        Select
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            disabled={formData.compensationType === "Lender Paid"}
                            type="number"
                            id="revenueDollar"
                            placeholder="Enter"
                            value={formData?.revenueDollar}
                            onChange={(e) =>
                                handlePerchantgeChange("revenueDollar", e.target.value)
                            }
                        />
                    </InputGroup>
                </div>)
                }



                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="compensationType" className="font-weight-bold">Compensation Type</Form.Label>
                    <select
                        id="compensationType"
                        className="form-select"
                        value={formData.compensationType}
                        onChange={(e) => {
                            // e.target.value === "Lender Paid" && handlePerchantgeChange("BPS", defaultPersentage)
                            handleInputChange("compensationType", e.target.value)
                        }
                        }
                        isInvalid={errors?.compensation_type && errors?.compensation_type?.length > 0}
                    >
                        <option className="" disabled>Select Compensation Type</option>
                        <option value="Borrower Paid">Borrower Paid</option>
                        <option value="Lender Paid">Lender Paid</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.compensation_type?.[0]}
                    </Form.Control.Feedback>
                </div>

                <div className="col-md-4 col-lg-3 mb-2">
                    <Form.Label htmlFor="lockTerm" className="font-weight-bold">Lock Term</Form.Label>
                    <select
                        id="lockTerm"
                        className="form-select"
                        value={formData.lockTerm}
                        onChange={(e) =>
                            handleInputChange("lockTerm", e.target.value)
                        }
                        isInvalid={errors?.lock_term_days && errors?.lock_term_days?.length > 0}
                    >
                        <option className="" disabled>Select Lock Term</option>
                        <option value="30">30 Days</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {errors?.lock_term_days?.[0]}
                    </Form.Control.Feedback>
                </div>
            </div>
        </Form>
    )
}

export default LoanDetails
