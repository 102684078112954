import Layout1 from "../layouts/backend/Layout1";

// //main
import Dashbord from "../views/backend/Main/Dashbord";

// Product
import UsersList from "../views/backend/Main/UsersList";

// icon-heroicon
import Lead from "../views/backend/Main/Lead";
import LeadeditQuote from "../views/backend/Main/LeadeditQuote";
import LeadeView from "../views/backend/Main/LeadeView";
import LeadEdit from "../views/backend/Main/LeadEdit";
import UsersEdit from "../views/backend/Main/UsersEdit";
import ProfileEdit from "../views/backend/App/UserManagement/ProfileEdit";
import LeadAdd from "../views/backend/Main/LeadAdd";
import UsersAdd from "../views/backend/Main/UsersAdd";
import Payment from "../views/backend/Main/Payment";
import PaymentAdd from "../views/backend/Main/PaymentAdd";
import LeadGeneratQuoteView from "../views/backend/Main/LeadGeneratQuoteView";

export const Layout1Route = [
  {
    path: "/",
    element: <Layout1 />,
    children: [
      {
        path: "/",
        element: <Dashbord />,
      },
      {
        path: "/leads",
        element: <Lead />,
      },
      {
        path: "/lead-add",
        element: <LeadAdd />,
      },
      {
        path: "/lead-edit/:leadId",
        element: <LeadEdit />,
      },
      {
        path: "/lead-edit-quote/:leadId",
        element: <LeadeditQuote />,
      },
      {
        path: "/lead-view/:leadId",
        element: <LeadeView />,
      },
      {
        path: "/lead-generat-quoted-view/:leadId",
        element: <LeadGeneratQuoteView />,
      },
      {
        path: "/users-list",
        element: <UsersList />,
      },
      {
        path: "/users-add",
        element: <UsersAdd />,
      },
      {
        path: "/users-edit/:userId",
        element: <UsersEdit />,
      },
      {
        path: "/payment",
        element: <Payment />,
      },
      {
        path: "/payment-add",
        element: <PaymentAdd />,
      },
      {
        path: "/profile-edit",
        element: <ProfileEdit />,
      },
    ],
  },
];
