import { bind } from "leaflet";
import { apiBaseSlices } from "./baseAPI";

export const leadsSlices = apiBaseSlices.injectEndpoints({
    endpoints: (builder) => ({
        getLeadList: builder.mutation({
            query: ({ token, currentPage, search }) => ({
                url: `leads?page=${currentPage}&search=${search}`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        getLead: builder.mutation({
            query: ({ leadId, token }) => ({
                url: `leads/${leadId}`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        getCounties: builder.mutation({
            query: ({token}) => ({
                url: `counties`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        createLead: builder.mutation({
            query: ({ payload, token }) => ({
                url: "leads",
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        updateLead: builder.mutation({
            query: ({ payload, leadId, token }) => ({
                url: `leads/${leadId}`,
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        deleteLead: builder.mutation({
            query: ({ leadId, token }) => ({
                url: `leads/${leadId}`,
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }),
        }),

        // Quote
        getQuote: builder.mutation({
            query: ({ leadId, token }) => ({
                url: `leads/${leadId}/quote`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }),
        }),

        getRateStack: builder.mutation({
            query: ({ leadId, token }) => ({
                url: `leads/${leadId}/rate-stack`,
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }),
        }),

        updateQuote: builder.mutation({
            query: ({ payload, leadId, token }) => ({
                url: `quotes/${leadId}`,
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        postPreview: builder.mutation({
            query: ({ payload, leadId, token }) => ({
                url: `leads/${leadId}/preview`,
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: payload,
            }),
        }),

        getGeneratedQuote: builder.mutation({
            query: ({ payload, leadId, token }) => {
                return {
                    url: `leads/${leadId}/generated-quote`,
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: payload,
                };
            },
        })        

    }),
});

export const {
    useGetLeadListMutation,
    useGetCountiesMutation,
    useGetLeadMutation,
    useCreateLeadMutation,
    useUpdateLeadMutation,
    useDeleteLeadMutation,
    useGetQuoteMutation,
    useGetRateStackMutation,
    useUpdateQuoteMutation,
    usePostPreviewMutation,
    useGetGeneratedQuoteMutation,
} = leadsSlices;
