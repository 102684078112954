import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import { useDeleteLeadMutation, useGetLeadListMutation } from '../../../api/leadsAPISlices'
import Toaster from '../../../components/utility/Toaster'
import CustomPagination from '../../../components/utility/CustomPagination'
import PopupWrapper from '../../../components/utility/PopupWrapper'
import CommonButton from '../../../components/utility/CommonButton'

const colums = [
    {
        lavel: "Name",
        key: "name"
    },
    {
        lavel: "Phone",
        key: "phone"
    },
    {
        lavel: "Address",
        key: "address"
    },
    {
        lavel: "Property value",
        key: "property_value"
    },
    {
        lavel: "Property type",
        key: "property_type"
    },
    {
        lavel: "Action",
        key: "action"
    }
]

const Lead = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [LeadList, setLeadList] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [show, setShow] = useState(false);
    const [leadIdForDelete, setleadIdForDelete] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [search, setSearch] = useState("");

    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));

    const [deleteLead] = useDeleteLeadMutation();
    const [getLeadList] = useGetLeadListMutation();

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    if (user?.role =='admin') {
        if (colums.filter((col) => col.key === 'user_name').length === 0) {
            colums.splice(2, 0, {
                lavel: "Assigned to",
                key: "user_name"
            })
        }
    }

    const getLeadData = async () => {
        setIsLoading(true)
        await getLeadList({ token, currentPage, search })
            .unwrap()
            .then((res) => {
                setLeadList(res)
                setIsLoading(false)
            })
            .catch((error) => {

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')

                setIsLoading(false)
            })
    }

    useEffect(() => {
        getLeadData()
    }, [currentPage, currentPage, search])

    const onDleletePopup = async (leadId) => {
        setShow(true)
        setleadIdForDelete(leadId)
    }

    const onDlelete = async (leadId) => {

        setIsDeleting(true)

        await deleteLead({ leadId, token })
            .unwrap()
            .then((res) => {

                getLeadData()

                setShowToster(true)
                setTosterMassage("Successfully deleted")
                setTosterStatus('success')

                setIsDeleting(false)

                setShow(false)
            })
            .catch((error) => {
                console.log("error", error)

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')

                setIsDeleting(false)
            })
    }


    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Leads</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Form onSubmit={(e) => {e.preventDefault(); setSearch(searchInput);}} className="mr-3 position-relative">
                                            <Form.Group className="mb-0">
                                                <Form.Control type="text"
                                                    className="form-control"
                                                    id="exampleInputText"
                                                    placeholder="Search Lead"
                                                    onChange={(e) => {
                                                        e.target.value?.length === 0 && setSearch('')
                                                        setSearchInput(e.target.value)
                                                    }
                                                    }
                                                />
                                                <Link to="#" className="search-link" onClick={() => setSearch(searchInput)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </Link>
                                            </Form.Group>
                                        </Form>
                                        <Link to="/lead-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Lead
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading ?
                            (<div className='text-center mt-5 pt-5'>
                                <div className="spinner-border text-warning mr-2" role="status">
                                    <span className="visually-hidden"></span>
                                </div>
                            </div>)
                            : LeadList?.data?.length > 0 ?
                                <>
                                    <Row>
                                        <Col lg="12">
                                            <Card className="card-block card-stretch border-back">
                                                <Card.Body className="p-0">
                                                    <div className="table-responsive">
                                                        <table className="table data-table mb-0">
                                                            <thead className="table-color-heading">
                                                                <tr className="">
                                                                    {colums.map((col, i) => (
                                                                        <th index={i} scope="col" className={col?.key === 'action' && "text-center"}>
                                                                            {col.lavel}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    LeadList?.data?.map((row, index) => (
                                                                        <tr key={index} className="white-space-no-wrap">
                                                                            {colums.map((col) => (
                                                                                <td>
                                                                                    {
                                                                                        col?.key === 'action' ?
                                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                                    <Link className="" to={`/lead-edit/${row.id}`}>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </OverlayTrigger>
                                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                                    <Button className="badge bg-danger border-none p-1 py-0" style={{ height: "30px" }} onClick={() => onDleletePopup(row.id)}>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ marginTop: '-8px' }}>
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                                        </svg>
                                                                                                    </Button>
                                                                                                </OverlayTrigger>
                                                                                            </div>
                                                                                            : col?.key === 'name' ?
                                                                                                `${row?.first_name || ""} ${row?.last_name || ""}`
                                                                                                : row?.[col?.key]
                                                                                    }
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="12" className='text-center'>
                                            <CustomPagination
                                                current_page={currentPage}
                                                last_page={LeadList?.meta?.last_page}
                                                onPageChange={onPageChange}
                                            />
                                        </Col>
                                    </Row>
                                </> :
                                <div className='pt-5 mt-5 mb-5 text-center'>
                                    No data found
                                </div>
                        }
                    </Col>
                </Row>
            </Container>

            <PopupWrapper title="Delete" show={show} setShow={setShow} >
                <p className='text-danger'> Are you sure you want to delete this Lead?</p>

                <CommonButton isLoading={isDeleting} text="Delete" onSubmit={() => { onDlelete(leadIdForDelete) }} />
            </PopupWrapper>

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />
        </>
    )
}
export default Lead;