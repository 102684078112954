import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Nav, Tab, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { PatternFormat } from "react-number-format";
import imageCompression from 'browser-image-compression';
import user1 from '../../../../assets/images/user/1.avif'
import imagePlaceholder from '../../../../assets/images/user/image-placeholder.png';
import { useChangePasswrodMutation, useUpdateUserMutation } from '../../../../api/usersAPISlices'
import Toaster from '../../../../components/utility/Toaster'
import Multiselect from 'multiselect-react-dropdown';
import { getMeAPiCalling } from '../../../../components/utility/getMeData';
import { useGetProfileMutation } from '../../../../api/authApiSlices';
import states from "../../../../assets/json/states.json"

const personalInfoInitialValus = {
    fristName: '',
    lastname: '',
    email: '',
    phone: '',
    nmls: "",
    imageUrl: '',
    role: '',

    lenderPaidCompensation: '',
    licensedStates: [],
    defaultTitleCharges: '',
    defaultCreditAdVerificationFee: "",
    defaultAppraisalFee: '',
    companyNmls: "",
    companyName: "",
    companyLogo: "",
    defaultProcessingFee: 0,
    underwritingFee: 0,
}

const changePassoeardInitialValus = {
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
}

const ProfileEdit = () => {
    const [formData, setFormData] = useState(personalInfoInitialValus);
    const [changePassowardData, setChangePassowardData] = useState(changePassoeardInitialValus);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const companyImageRef = useRef(null);
    const fileInputRef = useRef(null);
    const [showToster, setShowToster] = useState(false);
    const [tosterMassage, setTosterMassage] = useState(false);
    const [tosterStatus, setTosterStatus] = useState(false);
    const [companyImage, setCompanyImage] = useState(null);
    const [errors, setErrors] = useState();

    const [changePasswrod] = useChangePasswrodMutation();
    const [updateUser] = useUpdateUserMutation();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));

    const [getProfile] = useGetProfileMutation()
    const logdenUerData = async () => {
        await getMeAPiCalling(getProfile)

        window.location.reload();
    }

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            fristName: user.first_name,
            lastname: user.last_name,
            email: user.email,
            phone: user.phone,
            nmls: user.nmls,
            managerId: user?.manager_id,
            isActive: user?.is_active,
            creditBalance: user?.credit_balance,
            photo: user?.photo,
            licensedStates: user?.licensed_states,
            lenderPaidCompensation: user?.lender_paid_compensation,
            defaultAppraisalFee: user?.default_appraisal_fee,
            companyName: user?.company_name,
            companyNmls: user?.company_nmls,
            defaultCreditAdVerificationFee: user?.default_services_you_cannot_shop_for_fee,
            defaultTitleCharges: user?.default_services_you_can_shop_for_fee,
            companyLogo: user?.company_logo,
            defaultProcessingFee: user?.default_processing_fee,
            underwritingFee: user?.underwriting_fee,
        }))
        // formData.
    }, [])

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleImageUpload = async (event, type) => {
        const file = event.target.files[0];
        if (file) {

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(file, options);
                if (type === "company") {
                    setCompanyImage(compressedFile)
                } else {
                    setUploadedImage(compressedFile);
                }
            } catch (error) {
                console.log(error);
            }

        }
    };

    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handlecompanyImageRef = () => {
        if (companyImageRef.current) {
            companyImageRef.current.click();
        }
    };

    const handleSelect = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const handleRemove = (selectedList) => {
        const selectedCodes = selectedList.map((item) => item.value);
        setFormData({ ...formData, licensedStates: selectedCodes });
    };

    const selectedObjects = formData?.licensedStates?.length > 0 && states.filter((state) =>
        formData?.licensedStates.includes(state.value)
    );

    const inputPasswordChange = (field, value) => {
        setChangePassowardData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true)


        const payloadData = {
            first_name: formData.fristName,
            last_name: formData.lastname,
            nmls: formData.nmls,
            phone: formData.phone,
            licensed_states: formData.licensedStates || [],
            lender_paid_compensation: formData.lenderPaidCompensation,
            default_appraisal_fee: formData.defaultAppraisalFee,
            company_name: formData.companyName,
            company_nmls: formData.companyNmls,
            default_services_you_cannot_shop_for_fee: formData.defaultCreditAdVerificationFee,
            default_services_you_can_shop_for_fee: formData.defaultTitleCharges,
            default_processing_fee: formData?.defaultProcessingFee,
            underwriting_fee: formData?.underwritingFee,
            _method: "PUT",
        };

        const payload = new FormData();

        for (const [key, value] of Object.entries(payloadData)) {
            if (Array.isArray(value)) {
                value.forEach((item) => payload.append(`${key}[]`, item));
            } else {
                payload.append(key, value);
            }
        }

        if (uploadedImage) {
            payload.append('photo', uploadedImage);
        }

        if (companyImage) {
            payload.append('company_logo', companyImage);
        }

        const userId = user?.id

        await updateUser({ payload, userId, token })
            .unwrap()
            .then((res) => {

                logdenUerData()
                setShowToster(true)
                setTosterMassage("successfully saved")
                setTosterStatus('success')
                setErrors([])

                // navigate("/");
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
                setErrors(error?.data?.errors)
            });
    };

    const handleChangePassoward = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const payload = {
            current_password: changePassowardData.currentPassword,
            new_password: changePassowardData.newPassword,
            new_password_confirmation: changePassowardData.verifyPassword
        }

        await changePasswrod({ payload, token })
            .unwrap()
            .then((res) => {
                setIsLoading(false)

                setShowToster(true)
                setTosterMassage("successfully saved")
                setTosterStatus('success')
            })
            .catch((error) => {
                setIsLoading(false)

                setShowToster(true)
                setTosterMassage(error?.data?.message)
                setTosterStatus('error')
            })
    }

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0 text-center">
                                    <div className="mm-edit-list usr-edit text-center">
                                        <Nav variant="pills" className="mm-edit-profile d-flex">
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="personal-information">Personal Information</Nav.Link>
                                            </li>
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="chang-pwd">Change Password</Nav.Link>
                                            </li>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg="12">
                            <div className="mm-edit-list-data">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                                        <Card>
                                            <Card.Body>
                                                <Form onSubmit={onSubmit} className='py-2'>
                                                    <Row className="form-group align-items-center">
                                                        <Col sm="6" md="4">
                                                            <div
                                                                className="profile-img-edit"
                                                                style={{ display: "inline-block", cursor: "pointer" }}
                                                                onClick={handleDivClick}
                                                            >
                                                                <p className='font-weight-bold mb-1' style={{ fontSize: '18px' }}>Your Photo:</p>
                                                                <div className="crm-profile-img-edit">
                                                                    <img
                                                                        className="crm-profile-pic rounded-circle avatar-100"
                                                                        src={uploadedImage ? URL.createObjectURL(uploadedImage) : formData?.photo ? formData?.photo : user1}
                                                                        alt="profile-pic"
                                                                    />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <i className="las la-pen upload-button"></i>
                                                                        <input
                                                                            ref={fileInputRef}
                                                                            className="file-upload"
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={handleImageUpload}
                                                                            style={{ display: "none" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="6" md="4">
                                                            <div
                                                                className="profile-img-edit"
                                                                style={{ display: "inline-block", cursor: "pointer" }}
                                                                onClick={handlecompanyImageRef}
                                                            >
                                                                <p className='font-weight-bold mb-1' style={{ fontSize: '18px' }}>Company Logo:</p>
                                                                <div className="crm-profile-img-edit">
                                                                    <img
                                                                        className="crm-profile-pic rounded-3 border shadow-lg"
                                                                        style={{ maxHeight: 100 }}
                                                                        src={companyImage ? URL.createObjectURL(companyImage) : formData?.companyLogo ? formData?.companyLogo : imagePlaceholder}
                                                                        alt="profile-pic"
                                                                    />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <i className="las la-pen upload-button"></i>
                                                                        <input
                                                                            ref={companyImageRef}
                                                                            className="file-upload"
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={(e) => handleImageUpload(e, "company")}
                                                                            style={{ display: "none" }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                    </Row>

                                                    <Row className="align-items-center">
                                                        <Form.Group controlId="fname" as={Col} sm="6" md="4">
                                                            <Form.Label>First Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter First Name'
                                                                required
                                                                value={formData.fristName}
                                                                onChange={(e) =>
                                                                    handleInputChange("fristName", e.target.value)
                                                                }
                                                                isInvalid={errors?.first_name && errors?.first_name?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.first_name?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="lname" as={Col} sm="6" md="4">
                                                            <Form.Label>Last Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter Last Name'
                                                                required
                                                                value={formData.lastname}
                                                                onChange={(e) =>
                                                                    handleInputChange("lastname", e.target.value)
                                                                }
                                                                isInvalid={errors?.last_name?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.last_name?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="email" as={Col} sm="6" md="4">
                                                            <Form.Label>Email:</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder='Enter Email'
                                                                required
                                                                value={formData.email}
                                                                disabled
                                                                // onChange={(e) =>
                                                                //     handleInputChange("email", e.target.value)
                                                                // }
                                                                isInvalid={errors?.last_name?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.last_name?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="phone" as={Col} sm="6" md="4">
                                                            <Form.Label>Phone:</Form.Label>
                                                            {/* <Form.Control
                                                                type="phone"
                                                                placeholder='Enter Phone'
                                                                required
                                                                value={formData.phone}
                                                                onChange={(e) =>
                                                                    handleInputChange("phone", e.target.value)
                                                                }
                                                            /> */}
                                                            <PatternFormat
                                                                type="tel"
                                                                format="(###) ###-####"
                                                                mask="_"
                                                                value={formData.phone}
                                                                onValueChange={(values) => handleInputChange("phone", values.formattedValue)}
                                                                placeholder="(123) 456-7890"
                                                                required
                                                                className="form-control"
                                                            />
                                                            {errors?.phone &&
                                                                <p type="invalid" className='invalid text-danger' style={{ fontSize: '14px' }} >
                                                                    {errors?.phone}
                                                                </p>
                                                            }
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>NMLS:</Form.Label>
                                                            <Form.Control
                                                                type="role"
                                                                placeholder='Enter NMLS'
                                                                required
                                                                value={formData.nmls}
                                                                onChange={(e) =>
                                                                    handleInputChange("nmls", e.target.value)
                                                                }
                                                                isInvalid={errors?.nmls?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.nmls?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Lender Paid Compensation (%) :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder=''
                                                                value={formData.lenderPaidCompensation}
                                                                onChange={(e) =>
                                                                    handleInputChange("lenderPaidCompensation", e.target.value)
                                                                }
                                                                isInvalid={errors?.lender_paid_compensation?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.lender_paid_compensation?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Default Services You Can Shop For Fee ($) :</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={formData.defaultTitleCharges}
                                                                onChange={(e) =>
                                                                    handleInputChange("defaultTitleCharges", e.target.value)
                                                                }
                                                                isInvalid={errors?.default_services_you_can_shop_for_fee?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.default_services_you_can_shop_for_fee?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Default Services You Can't Shop For Fee ($) :</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                // placeholder='Enter Credit And Verification Fee'
                                                                value={formData.defaultCreditAdVerificationFee}
                                                                onChange={(e) =>
                                                                    handleInputChange("defaultCreditAdVerificationFee", e.target.value)
                                                                }
                                                                isInvalid={errors?.default_services_you_cannot_shop_for_fee?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.default_services_you_cannot_shop_for_fee?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Company NMLS:</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder=''
                                                                value={formData.companyNmls}
                                                                onChange={(e) =>
                                                                    handleInputChange("companyNmls", e.target.value)
                                                                }
                                                                isInvalid={errors?.company_nmls?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.company_nmls?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Company Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=''
                                                                value={formData.companyName}
                                                                onChange={(e) =>
                                                                    handleInputChange("companyName", e.target.value)
                                                                }
                                                                isInvalid={errors?.company_name?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.company_name?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} sm="6" md="4">
                                                            <Form.Label htmlFor="inputState">Licensed States</Form.Label>
                                                            <Multiselect
                                                                options={states}
                                                                selectedValues={selectedObjects}
                                                                onSelect={handleSelect}
                                                                onRemove={handleRemove}
                                                                displayValue="name"
                                                                placeholder="Choose all that apply"
                                                                style={{
                                                                    optionContainer: {
                                                                        height: "150px",
                                                                    }
                                                                }}
                                                                isInvalid={errors?.licensed_states?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.licensed_states?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Default Processing Fee:</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder=''
                                                                value={formData.defaultProcessingFee}
                                                                onChange={(e) =>
                                                                    handleInputChange("defaultProcessingFee", e.target.value)
                                                                }
                                                                isInvalid={errors?.default_processing_fee?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.default_processing_fee?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group controlId="role" as={Col} sm="6" md="4">
                                                            <Form.Label>Underwriting Fee:</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder=''
                                                                value={formData.underwritingFee}
                                                                onChange={(e) =>
                                                                    handleInputChange("underwritingFee", e.target.value)
                                                                }
                                                                isInvalid={errors?.underwriting_fee?.length > 0}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.underwriting_fee?.[0]}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>

                                                    <Button type="submit" variant="primary">
                                                        <div
                                                            style={{
                                                                transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                                            }}
                                                        >
                                                            <div className="spinner-border text-warning loadding-spinner mr-2"
                                                                style={{
                                                                    opacity: isLoading ? 100 : 0,
                                                                }}
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>

                                                            <span>Submit</span>
                                                        </div>
                                                    </Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                        <Card>
                                            <Card.Body>
                                                <Form onSubmit={handleChangePassoward} className='py-2'>

                                                    <Row className="align-items-center">
                                                        <div className='col-md-6'>
                                                            <Form.Group controlId="cpass">
                                                                <Form.Label>Current Password:</Form.Label>
                                                                <Form.Control
                                                                    type="Password"
                                                                    required
                                                                    value={changePassowardData.currentPassword}
                                                                    onChange={(e) =>
                                                                        inputPasswordChange("currentPassword", e.target.value)
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-md-6'></div>

                                                        <div className='col-md-6'>
                                                            <Form.Group controlId="npass">
                                                                <Form.Label>New Password:</Form.Label>
                                                                <Form.Control
                                                                    type="Password"
                                                                    required
                                                                    value={changePassowardData.newPassword}
                                                                    onChange={(e) =>
                                                                        inputPasswordChange("newPassword", e.target.value)
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-md-6'></div>

                                                        <div className='col-md-6'>
                                                            <Form.Group controlId="vpass">
                                                                <Form.Label>Confirm New Password:</Form.Label>
                                                                <Form.Control
                                                                    type="Password"
                                                                    required
                                                                    value={changePassowardData.verifyPassword}
                                                                    onChange={(e) =>
                                                                        inputPasswordChange("verifyPassword", e.target.value)
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </ Row>

                                                    <Button type="submit" variant="primary mt-2">
                                                        <div
                                                            style={{
                                                                transform: !isLoading ? 'translateX(-1.1rem)' : 'translateX(0)'
                                                            }}
                                                        >
                                                            <div className="spinner-border text-warning loadding-spinner mr-2"
                                                                style={{
                                                                    opacity: isLoading ? 100 : 0,
                                                                }}
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden"></span>
                                                            </div>

                                                            <span>Submit</span>
                                                        </div>
                                                    </Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <Toaster status={tosterStatus} message={tosterMassage} show={showToster} setShow={setShowToster} />

        </>
    )
}

export default ProfileEdit;